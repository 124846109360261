import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LoaderService } from 'src/app/common/services/loader.service';
import { SharedPopupService } from 'src/app/common/services/shared-popup.service';
import {
  IsTriggered,
  UploadSubmitObject,
  DefaultPlayButton,
} from 'src/app/common/models/upload.model';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { Subscription } from 'rxjs';
import { UploadService } from 'src/app/common/services/upload.service';
import { AlertMessageModel } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { forEach, sortBy } from 'lodash';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataUploadComponent {
  isfinalLoadTrigger: boolean = false;
  isToggleDropdown: boolean = false;
  ActiveModule1: boolean = false;
  ActiveModule2: boolean = false;
  selectedStudy: string = '';
  isErrorpopupshown: boolean = false;
  selectedClientStudies: string[] = [];
  isclientSelected: boolean = false;
  isDataUpdateSelected: boolean = false;
  isDataSourceSelected: boolean = false;
  isStudySelected: boolean = false;
  Filetypes = [
    { filetype: 'SPSS', is_selected: false },
    { filetype: 'GDF', is_selected: false },
    { filetype: 'CRDOM', is_selected: false },
  ];

  DataProcessTypes = [
    {
      process: 'Regular Data / Mapping Update',
      type: 'upload',
    },
    {
      process: 'Restatement',
      type: 'restatement',
    },
  ];

  DataSource = [
    {
      name: 'Brand Health',
      type: 'brand-health',
    },
  ];

  Folders = {
    inclusion: ['inclusion', 'new-variable-mapping'],
    timeperiod: ['timeperiod'],
  };
  FileFormat: any;
  UploadObject: any;
  UserClientStudyData: any;
  Is_Submit: boolean = false;
  totalfiles: any;
  file: any;
  IsUploadFileStatus: any = [];
  intervalId: any;
  StatusUpdate = {
    section1: true,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
    section7: false,
    section9: false,
  };
  validuploadFileName: string = '';
  public SubmitObject: UploadSubmitObject = {
    Client: '',
    study: '',
    dataProces: '',
    filetype: '',
  };
  isTriggered: IsTriggered = {
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
    section7: false,
    section9: false,
  };
  indexCount: number = 0;
  isFilenotAvailable: boolean = true;
  fomInputPlayButtonEnable: boolean = true;
  fomFilePlayButtonEnable: boolean = true;
  timePeriodPlayButtonEnable: boolean = true;
  isButtonDisableWhenClickOnTrigger = true;
  items = [1, 2, 3, 4, 5, 6, 7, 8];
  dropdownSelection = {
    client_name: '',
    study: '',
    dataProces: '',
    dataSource: '',
    fileType: '',
    JobType: '',
    FolderName: '',
  };

  dropdownSelectedText = {
    client_name: '',
    study: '',
    dataProces: '',
    dataSource: '',
    fileType: '',
    JobType: '',
    FolderName: '',
  };

  $unSubscribeSubmitData1!: Subscription;
  @Input() userManagementShown: boolean = false;
  @Input() excelExport: boolean = false;
  @Input() helpGuide: boolean = false;
  @Input() pptExport: boolean = false;
  @Input() repository: boolean = false;
  @Input() exportDownloads: boolean = false;
  @ViewChild('dataSanityRawDataConfig') dataSanityRawDataConfig:
    | ElementRef
    | undefined;
  @ViewChild('ChooseFile') sanityInputFile: ElementRef | undefined;

  //@Input() landingdata: any;
  selectedmoduleCell: number | null = null;
  statusdata: any;
  inputFileName = {
    sanityInput: 'Choose File from system',
    inclusionInput: 'Inclusion File (New variable list)',
    mappingInput: 'Mapping File',
    timeperiodInput: 'TP inputs file* and Suppress file',
    fomInput: '',
    fomFile: '',
    finalLoadInput: 'Choose File from system',
  };
  BindFileName = {
    sanityInput: '',
    inclusionInput: '',
    mappingInput: '',
    timeperiodInput: '',
    fomInput: '',
    fomFile: '',
    finalLoadInput: '',
  };

  defaultPlayButton: DefaultPlayButton = {
    section1: true,
    section2: true,
    section3: true,
    section4: true,
    section5: true,
    section6: true,
    section7: true,
    section8: true,
    section9: true,
  };

  DeleteIndexTypes = [
    {
      IndexName: 'FOM Mapping',
      originalIndexName: 'fom_mapping_data',
      is_selected: false,
    },
    {
      IndexName: 'Time Period Mapping',
      originalIndexName: 'time_period_mapping_data',
      is_selected: false,
    },
    {
      IndexName: 'Grid Status',
      originalIndexName: 'file_upload_status',
      is_selected: false,
    },
  ];

  Is_SubmitClick: boolean = false;

  showUploadGrid: boolean = true;
  intervalTime = 5000;
  constructor(
    public SharedService: SharedPopupService,
    private Loader: LoaderService,
    private crossTabService: CrosstabService,
    private uploadService: UploadService,
    private renderer: Renderer2,
    private _alertService: AlertMessageService
  ) {}
  ngOnInit() {
    this.selectedmoduleCell = 1;
    this.BindFileName.sanityInput = this.inputFileName.sanityInput;
    this.BindFileName.inclusionInput = this.inputFileName.inclusionInput;
    this.BindFileName.mappingInput = this.inputFileName.mappingInput;
    this.BindFileName.timeperiodInput = this.inputFileName.timeperiodInput;
    this.BindFileName.fomInput = this.inputFileName.fomInput;
    this.BindFileName.fomFile = this.inputFileName.fomFile;
    this.BindFileName.finalLoadInput = this.inputFileName.finalLoadInput;

    this.getUserClientStudy();
    //  if(this.UploadObject.length > 0){
    //    setInterval(() => {
    //     this.starthttprequest();
    //   }, 10000);
    //  }
  }
  ngAfterViewInit() {
    if (this.Is_Submit) {
      this.renderer.setStyle(
        this.dataSanityRawDataConfig?.nativeElement,
        'opacity',
        '100%'
      );
    }
  }

  toggleDropDown() {
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  getSectionActive(section: any) {
    if (this.statusdata != undefined) {
      if (this.statusdata.length > 0) {
        switch (section) {
          case 'section1':
            if (this.statusdata.length > 0) {
              if (this.statusdata[0].consumable_file_flag) {
                this.StatusUpdate.section1 = true;
                if (
                  this.totalfiles == undefined ||
                  this.totalfiles.length == 0
                ) {
                  this.BindFileName.sanityInput =
                    this.inputFileName.sanityInput;
                }
                return true;
              } else {
                this.StatusUpdate.section1 = false;
                this.renderer.setStyle(
                  this.dataSanityRawDataConfig?.nativeElement,
                  'opacity',
                  '0.5'
                );
                return false;
              }
            } else {
              return true;
            }

          case 'section2':
            if (this.statusdata[0].inclusion_file_flag) {
              this.StatusUpdate.section2 = true;
              return true;
            } else {
              this.StatusUpdate.section2 = false;
              return false;
            }
          case 'section3':
            if (this.statusdata[0].variable_mapping_flag) {
              this.StatusUpdate.section3 = true;
              return true;
            } else {
              this.StatusUpdate.section3 = false;
              return false;
            }
          case 'section4':
            if (this.statusdata[0].tp_flag) {
              this.StatusUpdate.section4 = true;
              return true;
            } else {
              this.StatusUpdate.section4 = false;
              return false;
            }
          case 'section5':
            if (this.statusdata[0].fom_flag) {
              this.StatusUpdate.section5 = true;
              return true;
            } else {
              this.StatusUpdate.section5 = false;
              return false;
            }
          case 'section6':
            if (this.statusdata[0].fom_input_flag) {
              this.StatusUpdate.section6 = true;
              return true;
            } else {
              this.StatusUpdate.section6 = false;
              return false;
            }
          case 'section7':
            if (this.statusdata[0].fom_file_flag) {
              this.StatusUpdate.section7 = true;
              return true;
            } else {
              this.StatusUpdate.section7 = false;
              return false;
            }
          case 'section9':
            if (this.statusdata[0].fd_flag) {
              this.StatusUpdate.section9 = true;
              return true;
            } else {
              this.StatusUpdate.section9 = false;
              return false;
            }
          // Add more cases as needed
          default:
            if (this.statusdata[0].consumable_file_flag) {
              return true;
            } else {
              return false;
            }
        }
      } else {
        return this.enableSection(section);
      }
    }
    // else{
    //   if(section === "section1"){
    //     this.StatusUpdate.section1= true;
    //     return true;
    //   }
    // }
    return false;
  }
  enableSection(section: any) {
    switch (section) {
      case 'section1':
        this.StatusUpdate.section1 = true;
        return true;
      case 'section2':
        return false;

      case 'section3':
        return false;

      case 'section4':
        this.StatusUpdate.section4 = false;
        return false;

      case 'section5':
        this.StatusUpdate.section5 = false;
        return false;
      case 'section6':
        this.StatusUpdate.section6 = false;
        return false;

      // Add more cases as needed
      default:
        return false;
    }
  }

  DownloadUsageLogReport() {
    this.Loader.showLoader();
    let clientName = this.UploadObject[0].client_name;
    let studyName = this.UploadObject[0].study;
    let fileType = this.UploadObject[0].fileType;
    let dataSource = this.UploadObject[0].dataSource;
    let dataProcess = this.UploadObject[0].dataProces;
    this.uploadService
      .ExcelExportForUsageLog(
        clientName,
        studyName,
        dataSource,
        dataProcess,
        fileType
      )
      .subscribe(
        (excelBlob: Blob) => {
          let fileName =
            clientName +
            '_' +
            studyName +
            '_' +
            dataSource +
            '_' +
            dataProcess +
            '_' +
            fileType +
            '_Usage Log.xlsx';
          // Trigger file download
          this.downloadFile(excelBlob, fileName);
          this.Loader.hideLoader();
        },
        (error) => {
          console.error('Error exporting to Excel', error);
          this.errorPopup('Something went wrong, please try again');
          this.Loader.hideLoader();
        }
      );
    // this.uploadService.ExcelExportForUsageLog(clientName, studyName, fileType).subscribe((exportFile) => {
    //   this.Loader.hideLoader();
    //   const blob = new Blob([exportFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   const Url = window.URL.createObjectURL(blob);
    //   const anchor = document.createElement('a');
    //   anchor.download = clientName+"_"+studyName+'_Usage Log.xlsx';
    //   anchor.href = Url;
    //   anchor.click();
    // }, (fileDownloadError:any) => {
    //   this.Loader.hideLoader();
    //   this.errorPopup(fileDownloadError);
    //   this.isErrorpopupshown = true;
    // });
  }

  private downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getSectionStatus(section: string, IsIconChange: boolean): any {
    if (this.statusdata != undefined) {
      switch (section) {
        case 'section1':
          // this.renderer.setStyle(this.dataSanityRawDataConfig?.nativeElement, "opacity", "100%");
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].consumable_file_status)
              : this.statusdata[0].consumable_file_status
            : this.IsIconChange('not started');

        case 'section2':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].inclusion_file_status)
              : this.statusdata[0].inclusion_file_status
            : this.IsIconChange('not started');
        case 'section3':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].variable_mapping_status)
              : this.statusdata[0].variable_mapping_status
            : this.IsIconChange('not started');
        case 'section4':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].tp_status)
              : this.statusdata[0].tp_status
            : this.IsIconChange('not started');
        case 'section5':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].fom_block_status)
              : this.statusdata[0].fom_block_status
            : this.IsIconChange('not started');
        case 'section6':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].fom_input_status)
              : this.statusdata[0].fom_input_status
            : this.IsIconChange('not started');
        case 'section7':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].fom_file_status)
              : this.statusdata[0].fom_file_status
            : this.IsIconChange('not started');
        case 'section9':
          return this.statusdata.length > 0
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].fd_status)
              : this.statusdata[0].fd_status
            : this.IsIconChange('not started');
        case 'sanity':
          return this.statusdata.length > 0
            ? this.statusdata[0].data_sanity_status
            : 'not started';
        case 'IS':
          return this.statusdata.length > 0
            ? this.statusdata[0].fom_block_status == 'completed' &&
              this.statusdata[0].tp_status == 'completed'
              ? 'completed'
              : this.statusdata[0].data_sanity_status != 'completed'
              ? 'not started'
              : this.statusdata[0].fom_block_status == '' &&
                this.statusdata[0].tp_status == ''
              ? 'not started'
              : 'in-progress'
            : 'not started';
        // Add more cases as needed
        case 'finalDataLoad':
          return this.statusdata.length > 0
            ? this.statusdata[0].fd_status
            : 'not started';
        default:
          return 'Start';
      }
    }
    return '';
  }
  getStatusColor(section: string): string {
    // Map status to corresponding CSS class
    let status = this.getSectionStatus(section, false).toLowerCase();
    switch (status) {
      case 'in-progress':
        return '#FF971E';
      case 'failed':
        this.clearFileInput(section);
        return '#DB4F45';
      case 'completed':
        this.clearFileInput(section);
        return '#3BC47C';
      default:
        return '';
    }
  }
  getStatusIcon(section: string): string {
    let status: any;

    if (this.getSectionActive(section)) {
      status = this.getSectionStatus(section, true).toLowerCase();
    } else {
      status = this.getSectionStatus(section, false).toLowerCase();
    }
    switch (status) {
      case 'in-progress':
        return 'schedule';
      case 'failed':
        return 'cancel';
      case 'completed':
        return 'check_circle';
      default:
        return 'play_circle';
    }
  }
  IsIconChange(sectionStatus: string) {
    if (sectionStatus == 'in-progress') {
      return sectionStatus;
    } else if (sectionStatus == 'failed') {
      return sectionStatus;
    } else if (sectionStatus == 'completed') {
      return sectionStatus;
    } else {
      return 'start';
    }
  }
  getUploadDisableStatus(section: string) {
    let status = this.getSectionStatus(section, false).toLowerCase();

    if (section == 'section1' && status == 'completed') {
      this.isTriggered[section] = false;
      return false;
    } else {
      if (this.statusdata != undefined) {
        if (!this.statusdata.fom_flag) {
          if (section == 'section7' && status == 'completed') {
            this.isTriggered[section] = false;
            return false;
          } else if (section == 'section6' && status == 'completed') {
            this.isTriggered[section] = false;
            return true;
          }
        }
      }
    }
    switch (status) {
      case 'in-progress':
        this.isTriggered[section] = true;
        return true;
      case 'failed':
        this.isTriggered[section] = false;
        return false;
      case 'completed':
        this.isTriggered[section] = false;
        return true;
      default:
        //this.isTriggered[section] = false;
        return false;
    }
  }
  getUserClientStudy(): void {
    this.Loader.showLoader();
    this.FileFormat = this.Filetypes;
    this.SharedService.getClientList().subscribe((result) => {
      if (result.HasValidData) {
        this.UserClientStudyData = JSON.parse(result.Data);
      }
      this.Loader.hideLoader();
    });
  }

  OnRadioToggle(query: any) {
    if (query) {
      query.is_selected = !query.is_selected;
    }
  }

  OnRadioModuleToggle(file: any) {
    this.UploadObject = [];
    // Set the is_selected property of the clicked module to true
    file.is_selected = true;

    // Find any other selected module and set its is_selected property to false
    let otherSelected = this.FileFormat.find(
      (x: any) => x.is_selected && x.filetype !== file.filetype
    );
    if (otherSelected != null && otherSelected != undefined) {
      otherSelected.is_selected = false;
    }
    // Update the user's moduleName with the selected module's module_name
    this.SubmitObject.Client = 'PMI';
    this.SubmitObject.filetype = file.filetype;
    this.SubmitObject.study = 'NCPT';

    this.UploadObject.push(this.SubmitObject);
    // Trigger the update of the clientStudyName based on the new moduleName
  }

  OnDeleteClick() {
    this.Loader.showLoader();
    let client_study_name = this.UploadObject[0].study;
    this.uploadService
      .deleteIndex(
        'test-folder-creation',
        client_study_name,
        this.DeleteIndexTypes
      )
      .subscribe(
        (res: any) => {
          //console.log(res);
          if (res.HasValidData) {
            this.Loader.hideLoader();
            this.errorPopup(res.Data, '');
            this.isToggleDropdown = false;
            this.DeleteIndexTypes.map((item) => {
              item.is_selected = false;
            });
            // Resolve the promise with the response
          } else {
            this.Loader.hideLoader();
            if (!this.isErrorpopupshown) {
              this.errorPopup(res.ExceptionMessage);
              this.isErrorpopupshown = true;
            }
          }
        },
        (error) => {
          this.Loader.hideLoader();
        }
      );
  }

  OnSubmitClick() {
    this.isToggleDropdown = false;
    this.hideMainDropDown('');
    if (this.intervalId != null && this.intervalId != undefined) {
      clearInterval(this.intervalId);
    }
    this.isErrorpopupshown = false;
    this.Is_SubmitClick = true;
    // if(this.UploadObject.length > 0){
    //   this.Is_Submit = true;
    //   this.indexCount = 0;
    //   }
    this.uploadService.$submitClickEvent.next(true);

    if (
      this.dropdownSelection.client_name != '' &&
      this.dropdownSelection.study != '' &&
      this.dropdownSelection.dataProces != '' &&
      this.dropdownSelection.dataSource != '' &&
      this.dropdownSelection.fileType != ''
    ) {
      this.UploadObject = [];
      this.UploadObject.push(this.dropdownSelection);
      if (this.UploadObject.length > 0) {
        this.Loader.showLoader();
        this.statusdata = [];
        this.showUploadGrid =
          this.dropdownSelection.dataProces == 'upload' ? true : false;
        //const selectedsubmitdata = JSON.parse(JSON.stringify(this.UploadObject[0]));
        this.starthttprequest(this.UploadObject[0]);
      }
    }
  }
  clearFileInput(section: string): void {
    if (this.sanityInputFile) {
      this.sanityInputFile.nativeElement.value = '';
    }
  }

  starthttprequest(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$unSubscribeSubmitData1 = this.uploadService
        .datauploadstatusandgriddata(data)
        .subscribe(
          (res: any) => {
            //console.log(res);
            if (res.HasValidData) {
              let jsonData = res;
              this.Loader.hideLoader();
              if (jsonData.Data.length > 0) {
                this.statusdata = JSON.parse(jsonData.Data);
                this.statusdata.sort((a: any, b: any) => b.sort_id - a.sort_id);
              } else {
                this.statusdata = [];
              }
              // this.showUploadGrid =
              //   this.dropdownSelection.dataProces == 'upload' ? true : false;
              resolve(res); // Resolve the promise with the response
            } else {
              this.Loader.hideLoader();
              if (!this.isErrorpopupshown) {
                this.errorPopup(res.ExceptionMessage);

                this.isErrorpopupshown = true;
                clearInterval(this.intervalId);
                if (this.dropdownSelection.dataProces == 'restatement') {
                  this.showUploadGrid = true;
                  this.Is_SubmitClick = false;
                }
              }
              reject(new Error(res.ExceptionMessage)); // Reject the promise with the error message
            }
          },
          (error) => {
            this.Loader.hideLoader();
            reject(error); // Reject the promise with the error
          }
        );
    });
  }
  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  checkFileName(event: any, section: string) {
    this.validuploadFileName = '';
    let selectedSubmitData = JSON.parse(JSON.stringify(this.UploadObject[0]));
    let clientName: string = selectedSubmitData.client_name.toLowerCase();
    clientName = clientName.replace(/\s+/g, '-');
    switch (section) {
      case 'section1':
        this.validuploadFileName = `RawData.zip`;
        return event.target.files[0].name == `RawData.zip`;
      case 'section2':
        this.validuploadFileName = `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_IE_FILE.csv`;
        return (
          event.target.files[0].name ==
          `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_IE_FILE.csv`
        );
      case 'section3':
        this.validuploadFileName = `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_Variable_Mapping.xlsx`;

        return (
          event.target.files[0].name ==
          `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_Variable_Mapping.xlsx`
        );
      case 'section4':
        this.validuploadFileName = `timeperiod.zip`;
        return event.target.files[0].name == `timeperiod.zip`;
      case 'section5':
        this.validuploadFileName = `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_Inputsfile.xlsx`;
        return (
          event.target.files[0].name ==
          `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_Inputsfile.xlsx`
        );
      case 'section7':
        this.validuploadFileName = `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_mapping.xlsx`;
        return (
          event.target.files[0].name ==
          `${clientName.toLowerCase()}_${selectedSubmitData.study.toLowerCase()}_fom_mapping.xlsx`
        );
      case 'section9':
        this.validuploadFileName = `Config_UAT.xlsx`;
        return event.target.files[0].name == `Config_UAT.xlsx`;
    }

    return false;
  }

  onFileInput(event: any, section: string) {
    if (section != 'section1' && section != 'section4') {
      if (
        event.target.files[0] &&
        event.target.files[0].type === 'application/x-zip-compressed'
      ) {
        this.errorPopup('ZIP files are not allowed.');
        event.target.value = ''; // Clear the file input
      }
    }
    this.validuploadFileName = '';
    if (!this.checkFileName(event, section)) {
      this.errorPopup(
        'Please follow FileName Nomenclature (' + this.validuploadFileName + ')'
      );
      event.target.value = '';
      return;
    }

    this.UpdateFileName(event, section);
    this.GetFileList(event.target.files);
    this.defaultPlayButton[section] = true;
    if (this.totalfiles.length > 0) {
      if (section == 'section5') {
        this.fomInputPlayButtonEnable = false;
      } else if (section == 'section7') {
        this.fomFilePlayButtonEnable = false;
      } else if (section == 'section9') {
        this.isfinalLoadTrigger = false;
      } else if (section == 'section4') {
        this.timePeriodPlayButtonEnable = false;
      } else {
        this.isFilenotAvailable = false;
      }
      this.isButtonDisableWhenClickOnTrigger = false;
    }
  }

  GetFileList(files: any) {
    this.totalfiles = [];
    for (let index = 0; index < files.length; index++) {
      this.file = files[index];
      this.totalfiles.push(this.file);
    }
  }

  UpdateFileName(event: any, section: string) {
    switch (section) {
      case 'section1':
        this.BindFileName.sanityInput = event.target.files[0].name;
        break;
      case 'section2':
        this.BindFileName.inclusionInput = event.target.files[0].name;
        break;
      case 'section3':
        this.BindFileName.mappingInput = event.target.files[0].name;
        break;
      case 'section4':
        this.BindFileName.timeperiodInput = event.target.files[0].name;
        break;
      case 'section5':
        this.BindFileName.fomInput = event.target.files[0].name;
        break;
      case 'section7':
        this.BindFileName.fomFile = event.target.files[0].name;
        break;
      default:
        this.BindFileName.finalLoadInput = event.target.files[0].name;
        break;
    }
  }
  onTrigger(section: string, folderName: string) {
    this.isErrorpopupshown = false;
    this.isTriggered[section] = true;
    this.defaultPlayButton[section] = false;

    if (this.totalfiles !== undefined && this.totalfiles.length > 0) {
      this.dropdownSelection.JobType = this.getJobNameBasedOnSection(section);
      this.dropdownSelection.FolderName = folderName;
      this.Loader.showLoader();
      this.isButtonDisableWhenClickOnTrigger = true;
      if (section == 'section6') {
        this.fomInputPlayButtonEnable = true;
      } else if (section == 'section7') {
        this.fomFilePlayButtonEnable = true;
      } else if (section == 'section4') {
        this.timePeriodPlayButtonEnable = true;
      } else if (section == 'section9') {
        this.isfinalLoadTrigger = true;
      } else {
        this.isFilenotAvailable = true;
      }

      this.uploadService
        .fileUpload(this.dropdownSelection, this.totalfiles, folderName)
        .subscribe(
          async (result: any) => {
            // Validate the response
            if (result.HasValidData) {
              if (this.UploadObject.length > 0) {
                this.totalfiles = [];
                let selectedsubmitdata = JSON.parse(
                  JSON.stringify(this.UploadObject[0])
                );
                clearInterval(this.intervalId);
                this.intervalId = setInterval(async () => {
                  try {
                    const response = await this.starthttprequest(
                      selectedsubmitdata
                    );
                    // Process the response
                    if (response.HasValidData) {
                      if (response.Data && response.Data.length > 0) {
                        // this.totalfiles.push(...response.Data); // Example processing, adjust as needed
                        // this.isFilenotAvailable = false;
                        if (this.totalfiles.length == 0) {
                          if (section == 'section6') {
                            this.fomInputPlayButtonEnable = true;
                          } else if (section == 'section7') {
                            this.fomFilePlayButtonEnable = true;
                          } else if (section == 'section9') {
                            this.isfinalLoadTrigger = true;
                          } else if (section == 'section4') {
                            this.timePeriodPlayButtonEnable = true;
                          } else {
                            this.isFilenotAvailable = true;
                          }
                          this.isButtonDisableWhenClickOnTrigger = false;
                        }
                      } else {
                        if (this.totalfiles.length == 0) {
                          if (section == 'section6') {
                            this.fomInputPlayButtonEnable = true;
                          } else if (section == 'section7') {
                            this.fomFilePlayButtonEnable = true;
                          } else if (section == 'section9') {
                            this.isfinalLoadTrigger = true;
                          } else if (section == 'section4') {
                            this.timePeriodPlayButtonEnable = true;
                          } else {
                            this.isFilenotAvailable = true;
                          }
                          this.isButtonDisableWhenClickOnTrigger = false;
                        }
                      }
                    }
                    // Example stopping condition
                    // if (this.totalfiles.length >= 10) { // Adjust this condition as needed
                    //     clearInterval(intervalId);
                    // }
                  } catch (error) {
                    console.error('Error in interval:', error);
                    clearInterval(this.intervalId); // Stop interval on error
                  }
                }, this.intervalTime);
              } else {
                this.fomFilePlayButtonEnable = true;
                this.fomInputPlayButtonEnable = true;
                this.isFilenotAvailable = true;
                this.isButtonDisableWhenClickOnTrigger = false;
                this.isfinalLoadTrigger = true;
                this.timePeriodPlayButtonEnable = true;
              }
            } else {
              this.isButtonDisableWhenClickOnTrigger = false;
            }
            // Hide the loader
            // this.Loader.hideLoader();
          },
          (error) => {
            this.isTriggered[section] = false;
            this.Loader.hideLoader();
            this.errorPopup('Error : ' + error.message);
          }
        );
    } else {
      this.errorPopup('Please upload files');
    }
  }
  getJobNameBasedOnSection(section: string) {
    switch (section) {
      case 'section1':
        if (this.dropdownSelection.fileType.toLowerCase() == 'spss')
          return 'sanity_spss';
        if (this.dropdownSelection.fileType.toLowerCase() == 'gdf')
          return 'sanity_gdf';
        if (this.dropdownSelection.fileType.toLowerCase() == 'crdom')
          return 'sanity_crdom';
        return 'sanity_spss';
      case 'section2':
        return 'inclusion';
      case 'section3':
        return 'variableMapping';
      case 'section4':
        return 'timpePeriod';
      case 'section6':
        return 'fomInput';
      case 'section7':
        return 'fomFile';
      default:
        return 'finalLoad';
    }
  }
  OnDownloadFile(section: string, folderName: string) {
    this.Loader.showLoader();
    let multifolder: any;
    if (section.toLowerCase() == 'section2') {
      // if (this.statusdata[0].new_variable_file_flag) {
      //   multifolder = this.Folders.inclusion;
      // }
      // else {
      multifolder = this.Folders.inclusion;
      //multifolder = folderName;
      //}
    } else if (section.toLowerCase() == 'section4') {
      multifolder = this.Folders.timeperiod;
    } else {
      multifolder = folderName;
    }
    this.uploadService
      .downloadFile(this.dropdownSelection, folderName, multifolder)
      .subscribe((result: any) => {
        //validate the response
        this.downloadPPTFile(result, multifolder);

        // hide the loader
        this.Loader.hideLoader();
      });
  }
  private downloadPPTFile(data: any, filename: string) {
    const filesJson = JSON.parse(data.Data);

    Object.keys(filesJson).forEach((fileName) => {
      const base64Content = filesJson[fileName].Base64Content;
      // Decode base64 content
      const binaryContent = atob(base64Content);
      // Convert binary content to ArrayBuffer
      const arrayBuffer = new ArrayBuffer(binaryContent.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryContent.length; i++) {
        uint8Array[i] = binaryContent.charCodeAt(i);
      }
      // Create blob from ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: filesJson[fileName].FileExtension,
      });

      // Create link element for download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filesJson[fileName].FileName;

      // Trigger download
      document.body.appendChild(a);
      a.click();
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    });
  }

  clearStudyData() {
    this.dropdownSelection.study = '';
    this.dropdownSelectedText.study = '';
    this.isStudySelected = false;
  }

  clearDataSourceData() {
    this.dropdownSelection.dataSource = '';
    this.dropdownSelectedText.dataSource = '';
    this.isDataSourceSelected = false;
  }

  clearDataProcessData() {
    this.dropdownSelection.dataProces = '';
    this.dropdownSelectedText.dataProces = '';
    this.isDataUpdateSelected = false;
  }

  clearFileTypeData() {
    this.dropdownSelection.fileType = '';
    this.dropdownSelectedText.fileType = '';
  }

  resetNextDropdownSections(section: string) {
    // Reset selected study when client changes
    switch (section.toLowerCase()) {
      case 'client':
        this.clearStudyData();
        this.clearDataSourceData();
        this.clearDataProcessData();
        this.clearFileTypeData();
        break;
      case 'study':
        this.clearDataSourceData();
        this.clearDataProcessData();
        this.clearFileTypeData();
        break;
      case 'datasource':
        this.clearDataProcessData();
        this.clearFileTypeData();
        break;
      case 'dataprocess':
        this.clearFileTypeData();
        break;
    }
  }

  onClientChange(value: any, selecteddropdown: any) {
    this.hideMainDropDown('');
    this.UploadObject = [];
    this.Is_SubmitClick = false;
    this.uploadService.$submitClickEvent.next(false);

    if (selecteddropdown.toLowerCase() === 'client') {
      this.isStudySelected = false;
      let clientName = value;
      const selectedClient: any = this.UserClientStudyData.find(
        (client: { ClientName: string }) => client.ClientName === clientName
      );
      if (selectedClient) {
        this.dropdownSelection.client_name = clientName;
        this.dropdownSelectedText.client_name = clientName;
        this.selectedClientStudies = selectedClient.UniqueStudies;
        this.isclientSelected = true;
        this.resetNextDropdownSections(selecteddropdown);
      } else {
        this.selectedClientStudies = [];
      }
    } else if (selecteddropdown.toLowerCase() === 'study') {
      if (this.isclientSelected) {
        let studyName = value;
        const selectedStudy: any = this.selectedClientStudies.find(
          (study) => study === studyName
        );
        if (selectedStudy) {
          this.isStudySelected = true;
          // this.selectedStudy = selectedStudy;
          this.dropdownSelection.study = selectedStudy;
          this.dropdownSelectedText.study = selectedStudy;
          this.resetNextDropdownSections(selecteddropdown);
        } else {
        }
      } else {
        this.resetNextDropdownSections(selecteddropdown);
      }
    } else if (selecteddropdown.toLowerCase() === 'datasource') {
      let fileName = value;
      this.dropdownSelection.dataSource = fileName;
      let valSource: any = this.DataSource.find((s: any) => {
        return s.type === fileName;
      });
      if (valSource != undefined)
        this.dropdownSelectedText.dataSource = valSource.name;

      this.isDataSourceSelected = true;

      //Selecting upload data by default;
      this.resetNextDropdownSections(selecteddropdown);
      this.dropdownSelection.dataProces = this.DataProcessTypes[0].type;
      this.dropdownSelectedText.dataProces = this.DataProcessTypes[0].process;
      this.isDataUpdateSelected = true;
    } else if (selecteddropdown.toLowerCase() === 'dataprocess') {
      let fileName = value;
      this.dropdownSelection.dataProces = fileName;
      let valSource: any = this.DataProcessTypes.find((s: any) => {
        return s.type === fileName;
      });
      if (valSource != undefined)
        this.dropdownSelectedText.dataProces = valSource.process;
      this.isDataUpdateSelected = true;
      this.resetNextDropdownSections(selecteddropdown);
      //this.Is_Submit = true;
      // this.UploadObject.push(this.dropdownSelection);
    } else {
      let fileName = value;
      this.dropdownSelection.fileType = fileName;
      this.dropdownSelectedText.fileType = fileName;
    }
    if (
      this.dropdownSelection.client_name != '' &&
      this.dropdownSelection.dataProces != '' &&
      this.dropdownSelection.fileType != '' &&
      this.dropdownSelection.study != ''
    ) {
      //this.UploadObject.push(this.dropdownSelection);
      this.Is_Submit = true;
    } else {
      this.Is_Submit = false;
    }
  }

  mainDropdownList: any = {
    client: false,
    study: false,
    dataProces: false,
    dataSource: false,
    fileType: false,
  };

  toggleMainDropDown(event: any, selecteddropdown: string) {
    this.hideMainDropDown(selecteddropdown);

    this.mainDropdownList[selecteddropdown] =
      !this.mainDropdownList[selecteddropdown];
  }

  hideMainDropDown(selecteddropdown: string) {
    forEach(this.mainDropdownList, (s: any, key: any) => {
      if (key != selecteddropdown) {
        this.mainDropdownList[key] = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.$unSubscribeSubmitData1) {
      this.$unSubscribeSubmitData1.unsubscribe();
    }
    if (this.intervalId != null && this.intervalId != undefined) {
      clearInterval(this.intervalId);
    }
  }

  getMarginForScroll() {
    let tblRef: any = document.getElementById('grid_table_container_upload');
    if (tblRef != null) {
      let theadRef = tblRef.getElementsByTagName('thead');
      if (theadRef != null && theadRef.length > 0) {
        return `${theadRef[0].clientHeight}px 0 0 0px`;
      }
    }
    return 0;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    if (!this.isClickInside(event, '.drop-down-input-container')) {
      forEach(this.mainDropdownList, (s: any, key: any) => {
        this.mainDropdownList[key] = false;
      });
    }
    if (!this.isClickInside(event, '.selected-container')) {
      this.isToggleDropdown = false;
    }

    // Add logic to handle the click
  }

  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }
}
