import { Component, EventEmitter, Output, HostListener } from '@angular/core';
import { SharedPopupService } from 'src/app/common/services/shared-popup.service';
import { configuration_data } from 'src/app/common/models/configurations.model';
import { LoaderService } from 'src/app/common/services/loader.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { COMMON_CONSTANTS } from '../../../app/common/constants/common-constants';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { AlertMessageModel, results } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { environment } from 'src/app/environments/environment';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent {
  userList = ["anurag.nayak@kantar.com", "mitali.madhusmita@kantar.com","sagarsingh.rawat@kantar.com",
    "suman.kumar@kantar.com", "pavankumar.potta@kantar.com","pallavi.kv@kantar.com","laxman.lohra@kantar.com","basavaraj.kotagi@kantar.com",
    "shruti.chirde@kantar.com","sireesha.chowkillavari@kantar.com"];
       ark_userList = ["nataraj.bevinamarad@kantar.com","abhinaw.sumant@kantar.com","ishan.bhaway@kantar.com","amol.pramodwakchawa@kantar.com"];
  isDataUploadShown : boolean = false;
  configData: any;
  showContainer: boolean = false;
  showPopUp: boolean = false;
  showIframe: boolean = false;
  powerBIurl: SafeResourceUrl | undefined;
  hasAccess: boolean = false;
  isLoaded: boolean = false;
  isUserManagementShown: boolean = false;
  isAddClientStudyShown: boolean = false;
  isConfigurationShown: boolean = false;
  isAddClientButtonDisabled: boolean = false;
  @Output() close = new EventEmitter<void>();
  @Output() issnapshot = new EventEmitter<void>();
  private authSubscription!: Subscription;
  private roleSubscription!: Subscription;
  private env = environment.env;
  constructor(
    private router: Router,
    public SharedPopupService: SharedPopupService,
    private _configService: SharedPopupService,
    private Loader: LoaderService,
    private sanitizer: DomSanitizer,
    private _authenticateService: AuthenticationService,
    private _alertService: AlertMessageService,
    private msalService: MsalService) {
    this.msalService.handleRedirectObservable().subscribe((response) => {
      // Check if the response contains authentication token
      if (response && response.idToken) {
        this.GetUserAccess();
      }
    });
  }

  ngOnInit(): void {
    //this._authenticateService.startSessionTimeoutTimer(); // Initialize the session timeout timer
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
    // Add event listeners for mousemove and keydown to reset the session timeout timer
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
// debugger
    this.getAccess();

    //this.ShowAccessLevel()
  }
  ngOnDestroy() {
    // Unsubscribe from the authentication subscription when component is destroyed
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.roleSubscription) {
      this.roleSubscription.unsubscribe();
    }
    //document.removeEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.removeEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  }

  //@HostListener('document:click', ['$event'])
  //onDocumentClick(event: Event): void {
  //  //Check if the click event occurred outside the <div> block.
  //  document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
  //  document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  //}
  accessOnLoad=false;
  CrosstabAccess=false;
  SnapshotAccess=false;
  isSuperAdmin=false;
   ARKtabAccess=false;
  getAccess() {
    
    let moduleAccessBasedOnRole=localStorage.getItem('role');
    
    const access = this._authenticateService.GetLandingPageAccess();
    if (access !== null) {
      this.GetRoleValidation(access);
    }
    else {
      this.GetUserAccess();
    }

    //Condition for showing the module landing divs
    if(moduleAccessBasedOnRole!=null){
       ;
      let userData=JSON.parse(atob(moduleAccessBasedOnRole))
      let arr = _.map(_.uniqBy(userData.access, 'module_name'), (element:any)=>element.module_name);
      //console.log("ARR:",arr);
      // ;
      if(_.includes(this.userList,userData.email.toLowerCase())){
        this.isDataUploadShown = true;
      }else{
        this.isDataUploadShown = false;
      }
      // if(_.includes(this.ark_userList,userData.email.toLowerCase())){
      //   this.ARKtabAccess=true;
      //   this.CrosstabAccess=true;
      //   this.SnapshotAccess=false;
      // }
       if(userData.super_admin==true){
        this.accessOnLoad=true;
        this.isSuperAdmin=true;
      }
      else if(arr!=null){
        if(_.includes(arr,'Data Explorer') && _.includes(arr,'Snapshot')){
          this.accessOnLoad=true;
        }
        else if(_.includes(arr,'Data Explorer')){
          this.CrosstabAccess=true;
          this.SnapshotAccess=false;
          // this.ARKtabAccess=false;
        }
        else if(_.includes(arr,'Snapshot')){
          this.SnapshotAccess=true;
          this.CrosstabAccess=false;
          // this.ARKtabAccess=false;
        }
        // else if(_.includes(arr,'ARK')){
        //   this.SnapshotAccess=false;
        //   this.CrosstabAccess=false;
        //   // this.ARKtabAccess=true;
        // }
      }
      if(this.env !== undefined && this.env === 'dev'){
        this.ARKtabAccess=true;
      }
    }
  }
  GetUserAccess() {

    //if (localStorage['role'] != undefined && localStorage['role'] != localStorage['role']) {
    //  this._authenticateService.logout();
    //}
    this.Loader.showLoader();
    // Call API immediately after successful login
    this.authSubscription = this._authenticateService.AuthenticateUsers().subscribe((result: results) => {
      // checking response is valid
      if (result.HasValidData) {
        if (!this.hasAccess) {
          this.getAccess();
        }
      }
      this.Loader.hideLoader();
    });
  }


  GetRoleValidation(access: string) {   
    this.Loader.showLoader();
    // Call API immediately after successful login
    this.roleSubscription = this._authenticateService.roleValidation().subscribe((result: results) => {
      // checking response is valid
      if (result.HasValidData) {
        if (localStorage['role'] != undefined && localStorage['role'] != result.Data) {
          this._authenticateService.logout();
        }
        else {
          this.isLoaded = true;
          if (access == this._authenticateService.level4) {
            this.hasAccess = false; // User has access
          } else {
            this.hasAccess = true; // User does not have access
          }

          if (access === this._authenticateService.level1) {
            this.isAddClientStudyShown = true;
            this.isUserManagementShown = true;
            this.isConfigurationShown = true;
          }
          if (access === this._authenticateService.level2) {
            this.isUserManagementShown = true;
          }

        }
      }
      this.Loader.hideLoader();
    });
  }

  handleClick() {
    this.showContainer = false;
    this.getClientData();
  }
  
  handleSnapShotClick(){
    this.getSnapShotClient();
  }
  handleARKClick(){
    this.router.navigateByUrl('chatbotexpand');
  }
  pvpDemoClick() {
    this.showIframe = true;
    this.powerBIurl = this.sanitizer.bypassSecurityTrustResourceUrl(
      COMMON_CONSTANTS.powerBIurl
    );
  }

  closePopup() {
    this.showIframe = false;
  }

  getClientData(): void {
    this.Loader.showLoader();
    this._configService.getlandingClientList().subscribe((result) => {
      if (result.HasValidData) {
        this.configData = JSON.parse(result.Data);
        this.SharedPopupService.setPopUpData(this.configData);
        this.showPopUp = true;
        this.showSnapPopup=false;
      }
      this.Loader.hideLoader();
    });
  }
  showSnapPopup:boolean=false
  getSnapShotClient():void{
    this.Loader.showLoader();
    this._configService.getSnapshotlandingClientList().subscribe((result) => {
      if (result.HasValidData) {
        this.configData = JSON.parse(result.Data);
        //console.log(this.configData);
        this.configData.sort((a:any, b:any) => {  
          if (a.ClientName.toLowerCase() < b.ClientName.toLowerCase()) {  
              return -1; // a comes before b  
          }  
          if (a.ClientName.toLowerCase() > b.ClientName.toLowerCase()) {  
              return 1; // a comes after b  
          }  
          return 0; // a and b are equal  
      });  
      this.configData.forEach((item: any) => {
        if(item.UniqueSnapshots){
        item.UniqueSnapshots.sort((a: any, b: any) => {
            if (a.toLowerCase() < b.toLowerCase()) {
                return -1; // a comes before b
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1; // a comes after b
            }
            return 0; // a and b are equal
        });
      }
        if(item.UniqueStudies){
          item.UniqueStudies.sort((a: any, b: any) => {
            if (a.toLowerCase()  < b.toLowerCase() ) {
                return -1; // a comes before b
            }
            if (a.toLowerCase()  > b.toLowerCase() ) {
                return 1; // a comes after b
            }
            return 0; // a and b are equal
        });
        }
      
    });
        this.SharedPopupService.setPopUpData(this.configData);
        this.showSnapPopup = true;
        this.showPopUp=false;
      }
      this.Loader.hideLoader();
    });
  }
  decode(encodedData: string): string {
    // Use the atob function to decode the base64-encoded string
    const decodedData = atob(encodedData);

    // Return the decoded string
    return decodedData;
  }

  onCloseIconClickSnapShot(closePopUp: string): void {
    if (closePopUp == 'false') {
      this.showSnapPopup = false;
    }
  }

  onCloseIconClick(closePopUp: string): void {
    if (closePopUp == 'false') {
      this.showPopUp = false;
    }
  }

  /**
  * to show the error with message
  * @param message - . string text
  */
  AlertPopup(message: string, headerTxt: string = "Error"): void {
    const alertObj: AlertMessageModel = { header: headerTxt, message: message, show: true };
    this._alertService.showAlert(alertObj);
  }
  LogOut() {
    this._authenticateService.logout();
  }
  toggleContainer() {
    this.showContainer = !this.showContainer;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    //Check if the click event occurred outside the <div> block.
    //document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
    //document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
    if (this.showContainer == true) {
      if (!this.isClickInside(event, '.showPanel')) {
        this.showContainer = false;
      }
    }
   
  }
  private isClickInside(event: Event, className: string): boolean {
    const elements = document.querySelectorAll(className);
    for (let i = 0; i < elements.length; i++) {
      const container = elements[i];
      if (container.contains(event.target as Node)) {
        return true;
      }
    }
    return false;
  }

  ShowAccessLevel() {
    let access = this._authenticateService.GetAccess();
    if (access === this._authenticateService.level1) {
      this.isAddClientStudyShown = true;
      this.isUserManagementShown = true;
      this.isConfigurationShown = true;
    }
    if (access === this._authenticateService.level2) {
      this.isUserManagementShown = true;
    }
  }

  OnUserManagementClick() {
    this.router.navigateByUrl('user-management');
  }

  addClientStudy() {
    this.router.navigateByUrl('configuration');
  }

  OnDataUploadClick(){
    this.router.navigateByUrl('dataupload');
  }
}
