import { ChangeDetectionStrategy, Component, Input, OnInit, HostListener, ElementRef } from '@angular/core';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { CrossTabOutputItem, PairWiseDetails } from 'src/app/common/models/crosstab.model';
import * as _ from 'lodash';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import { AuthenticationService } from 'src/app/common/services/authentication.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-table-ouput',
  templateUrl: './table-ouput.component.html',
  styleUrls: ['./table-ouput.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // if any change occure then the component will get call.Optimize change detection for better performance
})
export class TableOuputComponent {
  @Input() isPairWise: boolean = false;
  @Input() DefaultConfidenceLevel: string | undefined = '95%';
  @Input() insufficientBase: string = '';
  @Input() LowBase: string = '';
  @Input() selectedIndex: string = '';
  @Input() isIndex: boolean = false;
  @Input() isSampleSizeBlock: boolean = false;
  @Input() isWeightedSampleBlock: boolean = false;
  @Input() selectedWeight: string = '';
  @Input() selectedDataFormat: string = '';
  LowBaseSample = COMMON_CONSTANTS.LowBase_Sample;
  InsufficientBaseSample = COMMON_CONSTANTS.InsufficientBase_Sample;
  @Input() threshold: string = '';
  ThresholdValue: number = 0;
  Sig_LowerLimt = COMMON_CONSTANTS.SigLowerLimit_95;
  Sig_UpperLimt = COMMON_CONSTANTS.SigUpperLimit_95;

  pairWiseVariables: string[] = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  @Input() data: any = [];
  @Input() isTranspose: boolean = false;
  cellWidth: number = 200;
  cellHeight: number = 45;
  crossTabReceivedData: any = [];
  @Input() isTotalBase: boolean = false;
  @Input() isAnswerBase: boolean = false;
  @Input() isSampleSize: boolean = false;
  @Input() isWeightedLabel: boolean = false;
  @Input() isWeightedNumerator: boolean = false;
  @Input() isUnWeightedNumerator: boolean = false;
  @Input() DefaultSampleSize: string | undefined;
  private _crossTabReceivedData: any;
  constructor(private dataSharingService: CrosstabService, private _authenticateService: AuthenticationService,
    public breakpointObserver: BreakpointObserver, private elementRef: ElementRef) { }

  ngOnInit() {
    this.LowBaseSample = this.LowBase == "" ? 0 : Number(this.LowBase);
    this.InsufficientBaseSample = this.insufficientBase == "" ? 0 : Number(this.insufficientBase);
    this.ThresholdValue = this.threshold == "" ? 0 : Number(this.threshold);
    //this.alignMentForMinWidth()
    this.getOutputData();
  }
  // alignMentForMinWidth() {
  //   //const minWidth = 1500;
  //   // if (window.innerWidth >= minWidth)
  //   //   this.cellHeight = 60;
  //   // this.breakpointObserver
  //   //   .observe(['(min-width: 1500px)'])
  //   //   .subscribe((state: BreakpointState) => {
  //       // if (state.matches) {
  //       //   let height = 45;
  //       //   let setHeight = 60;
  //       //   this.SetHeight(height, setHeight)
  //       // } else {
  //       //   let height = 60;
  //       //   let setHeight = 45;
  //       //   this.SetHeight(height, setHeight)
  //       // }
  //    // });
  // }
  // SetHeight(height: number, setHeight: number) {
  //   const rowHeaders = this.elementRef.nativeElement.querySelectorAll('.preview-row-header-text');
  //   if (rowHeaders) {
  //     rowHeaders.forEach((rowHeader: HTMLElement) => {
  //       const Height = rowHeader.offsetHeight; // Or use clientWidth for width without padding
  //       if (Height == height)
  //         rowHeader.style.height = setHeight + 'px';
  //     });
  //   }
  // }
  //@HostListener('document:click', ['$event'])
  //onDocumentClick(event: Event): void {
  //  //Check if the click event occurred outside the <div> block.
  //  document.addEventListener('mousemove', () => this._authenticateService.resetSessionTimeoutTimer());
  //  document.addEventListener('keydown', () => this._authenticateService.resetSessionTimeoutTimer());
  //}
  getOutputData() {
    this.dataSharingService.crossTabOuputDataSubject$.subscribe(({ data }) => {
      this.crossTabReceivedData = data;

      if (typeof window !== 'undefined') {
        (window as any)['CrossTabData'] = _.cloneDeep(data);
      }
    });
  }

  onUserAction() {
    this.getOutputData();
  }

  getHeader(item: any) {
    if (item) {
      let splitedText = item.split('|');
      return splitedText.join(' : ');
    }
    return item;
  }

  //To get the count of number of rows along with nesting
  getrowCount() {
    let rowCount = 0;
    if (
      this.data != null &&
      this.data != undefined &&
      this.data['Row'] != undefined
    ) {
      this.data.Row.forEach((x: any) => {
        rowCount = rowCount + x.Data.length;
      });
      if (this.data['RowNesting'] != undefined) {
        this.data.RowNesting.forEach((x: any) => {
          rowCount = rowCount * x.Data.length;
        });
      }
    }
    return rowCount;
  }
  // to get width of the cell based on the
  //getWidthofCellOnCount(panel: string, panelNested: string) {
  //  let rowCellCount = 1;
  //  let count = 0;
  //  if (this.data != null && this.data != undefined && this.data[panel] != undefined) {
  //    if (this.data[panelNested] != undefined) {

  //      for (let i = 0; i < this.data[panelNested].length; i++) {

  //        for (let j = 0; j < this.data[panelNested][i].Data.length; j++) {
  //          count = this.data[panelNested][i].Data[j].NestedArray.length;
  //          if (count > 0) {
  //            rowCellCount = rowCellCount * count;
  //          }
  //        }
  //        if (count == 0) {
  //          rowCellCount = rowCellCount * this.data[panelNested][i].Data.length;
  //        }
  //      }

  //    }

  //  }
  //  let heightWidth = (panel == 'Row') ? this.cellHeight : this.cellWidth;
  //  return rowCellCount * heightWidth;
  //}

  getWidthofCellOnCount(panel: string, panelNested: string): number {
    let rowCellCount = 1;
    let count = 1;
    if (this.data && this.data[panel]) {
      const nestedData = this.data[panelNested];

      if (nestedData) {
        nestedData.forEach((nestedItem: any) => {
          rowCellCount *= nestedItem.Data.length;
          nestedItem.Data.forEach((nestedArrayData: any) => {
            if (nestedArrayData.NestedArray.length > 0) {
              rowCellCount *= nestedArrayData.NestedArray.length;
            }
          });

          //if (nestedItem.NestedArray && nestedItem.NestedArray.length > 0) {
          //  nestedItem.NestedArray.forEach((innerItem: any) => {
          //    count *= innerItem.length;
          //  });
          //} else {
          //  rowCellCount *= nestedItem.Data.length;
          //}
        });
      }
    }

    const heightWidth = panel === 'Row' ? this.cellHeight : this.cellWidth;
    return rowCellCount * heightWidth;
  }

  getWidthofCellOnCount_Row(
    panel: string,
    panelNested: string,
    rowData: any
  ): number {
    let rowCellCount = 1;

    if (rowData.NestedArray.length > 0) {
      rowData.NestedArray.forEach((nestedItem: any) => {
        //rowCellCount *= nestedItem.NestedArray.length;

        if (nestedItem.NestedArray.length > 0) {
          rowCellCount *= nestedItem.NestedArray.length;
        } else {
          rowCellCount = rowData.NestedArray.length;
        }

        //nestedItem.NestedArray.forEach((nestedItemlvl2: any) => {
        //  rowCellCount *= nestedItemlvl2.length;
        //});
      });
    }

    // rowCellCount = rowData.NestedArray.length;

    const heightWidth = panel === 'Row' ? this.cellHeight : this.cellWidth;
    return rowCellCount * heightWidth;
  }

  getWidthofCellOnCount_Column(
    panel: string,
    panelNested: string,
    rowData: any
  ): number {
    let rowCellCount = 0;

    if (rowData.NestedArray.length > 0) {
      rowData.NestedArray.forEach((nestedItem: any) => {
        if (nestedItem.NestedArray.length > 0) {
          rowCellCount += nestedItem.NestedArray.length;
        } else {
          rowCellCount = rowData.NestedArray.length;
        }
      });
    } else {
      rowCellCount = 1;
    }
    const heightWidth = panel === 'Row' ? this.cellHeight : this.cellWidth;
    return rowCellCount * heightWidth;
  }

  getNestingCount(index: number, panel: string, panelNested: string) {
    let rowCellCount = 1;

    if (
      this.data != null &&
      this.data != undefined &&
      this.data[panel] != undefined
    ) {
      if (this.data[panelNested] != undefined) {
        for (let i = 0; i <= index; i++) {
          rowCellCount = rowCellCount * this.data[panelNested][i].Data.length;
        }
      }
    }
    return this.getWidthofCellOnCount(panel, panelNested) / rowCellCount;
  }

  getRowNestingCount(index: number, panel: string, panelNested: string) {
    let rowCellCount = 1;

    if (
      this.data != null &&
      this.data != undefined &&
      this.data[panel] != undefined
    ) {
      if (this.data[panelNested] != undefined) {
        for (let i = 0; i <= index; i++) {
          rowCellCount = rowCellCount * this.data[panelNested][i].Data.length;
        }
      }
    }
    return this.getWidthofCellOnCount(panel, panelNested) / rowCellCount;
  }
  //get the column and column nested count
  getrowCellCount() {
    let rowCellCount = 0;
    if (
      this.data != null &&
      this.data != undefined &&
      this.data['Column'] != undefined
    ) {
      this.data.Column.forEach((x: any) => {
        rowCellCount = rowCellCount + x.Data.length;
      });
      if (this.data['ColNesting'] != undefined) {
        this.data.ColNesting.forEach((x: any) => {
          rowCellCount = rowCellCount * x.Data.length;
        });
      }
    }
    return rowCellCount;
  }

  //get the count for row or column nesting count
  getNestedLevelCount(index: number, nesting: any) {
    let count = 1;
    for (let i = 0; i < index; i++) {
      count = count * nesting[i].Data.length;
    }
    return count;
  }

  // Margin for the scroll based on the count of nested rows and columns
  getMarginForScroll() {
    let colRowCount = 0;
    let RowColCount = 0;

    if (this.data != null && this.data != undefined) {
      if (this.data['Column'] != undefined && this.data['Column'].length > 0)
        colRowCount = 1;
      if (
        this.data['Column'] != undefined &&
        this.data['Column'].length > 0 &&
        this.data['Column'][0]['Data'][0]['NestedArray'].length > 0
      )
        colRowCount = 2;
      if (
        this.data['Column'] != undefined &&
        this.data['Column'].length > 0 &&
        this.data['Column'][0]['Data'][0]['NestedArray'].length > 0 &&
        this.data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray']
          .length > 0
      )
        colRowCount = 3;
      if (this.data['Row'] != undefined && this.data['Row'].length > 0)
        RowColCount = 1;
      if (
        this.data['Row'] != undefined &&
        this.data['Row'].length > 0 &&
        this.data['Row'][0]['Data'][0]['NestedArray'].length > 0
      )
        RowColCount = 2;
      if (
        this.data['Row'] != undefined &&
        this.data['Row'].length > 0 &&
        this.data['Row'][0]['Data'][0]['NestedArray'].length > 0 &&
        this.data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length >
        0
      )
        RowColCount = 3;
    }
    return `${45 * colRowCount}px 0 0 ${200 * RowColCount}px`;
  }

  getPercentage(rowItemData: any, colItemData: any) {
    let rowItem = rowItemData;
    let colItem = colItemData;
    (window as any)['CrossTabData_Index'] = [];
    if (this.isTranspose) {
      rowItem = { ...colItemData };
      colItem = { ...rowItemData };
    }

    // Find the row that matches the response codes
    const matchingRow = this.crossTabReceivedData.find(
      (row: CrossTabOutputItem) => {
        if (rowItem.isDerived && rowItem.CalculationType.toLowerCase() == 'mean' && this.selectedDataFormat == 'Respondent Level') {
          return (
            row.colResponseCode == colItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.colVariableCode == colItem.variablecode &&
            row.colChild == colItem.Child &&
            row.CalculationType.toLowerCase() == 'mean'
          )
        }
        else if (rowItem.isDerived && rowItem.CalculationType.toLowerCase() == 'mean' && this.selectedDataFormat != 'Respondent Level') {
          return (
            row.colResponseCode == colItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.colVariableCode == colItem.variablecode &&
            row.colChild == colItem.Child &&
            row.CalculationType.toLowerCase() == 'mean'
            && row.rowResponseCode === rowItem.responsecode
          )
        }
        else if (row.isCustomGroup) {
          return (
            row.rowResponseCode == rowItem.responsecode &&
            row.colResponseCode == colItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.colVariableCode == colItem.variablecode &&
            row.colChild == colItem.Child &&
            (row.customGroupId == rowItem.customGroupId ||
              row.customGroupId == colItem.customGroupId)
          );
        } else if (row.isCustomCalculation) {
          return (
            row.rowResponseCode == rowItem.responsecode &&
            row.colResponseCode == colItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.colVariableCode == colItem.variablecode &&
            row.colChild == colItem.Child &&
            (row.customCalculationId == rowItem.customCalculationId ||
              row.customCalculationId == colItem.customCalculationId)
          );
        } else {
          return (
            row.rowResponseCode == rowItem.responsecode &&
            row.colResponseCode == colItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.colVariableCode == colItem.variablecode &&
            row.colChild == colItem.Child
          );
        }
      });

    // if (this.isIndex && this.selectedIndex != "") {
    //   const matchingReferenceRow = this.crossTabReceivedData.find(
    //     (row: CrossTabOutputItem) => {
    //       if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
    //         return (
    //           (row.CalculationType.toLowerCase() == 'mean' &&
    //             row.rowVariableCode == rowItemData.variablecode &&
    //             row.colChild == this.selectedIndex)
    //         );
    //       } else {
    //         return (
    //           (
    //             row.rowResponseCode == rowItemData.responsecode &&
    //             row.rowVariableCode == rowItemData.variablecode &&
    //             row.colChild == this.selectedIndex)

    //         );
    //       }
    //     }
    //   );
    //   if (matchingReferenceRow != undefined) {
    //     const matchingRowCopy = { ...matchingRow };
    //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

    //     if (this.selectedWeight == "Unweighted") {
    //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
    //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

    //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
    //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;

    //     }
    //     else {
    //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
    //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

    //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
    //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;
    //     }
    //     let calculatedPercentage: number;
    //     let ShareNumber = this.isTotalBase
    //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
    //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);

    //     if (ShareNumber == null) {
    //       matchingRowCopy.ColorCode = "black";
    //       return 'NA';
    //     }
    //     else {
    //       calculatedPercentage = ((matchingRowCopy.isDerived && this.isIndex && this.selectedIndex != ""))
    //         ? Number((ShareNumber * 100).toFixed(1)) : ((matchingRowCopy.isDerived && matchingRowCopy.CalculationType == 'mean')|| matchingRowCopy.isCustomCalculation)? Number(ShareNumber.toFixed(1))
    //           : Number((ShareNumber * 100).toFixed(1));

    //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
    //         matchingRowCopy.ColorCode = "red";
    //       }
    //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
    //         matchingRowCopy.ColorCode = "green";
    //       }
    //       else {
    //         matchingRowCopy.ColorCode = "black";
    //       }
    //     }
    //     if(matchingRow!=null){
    //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
    //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
    //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
    //     }
    //     // this.crossTabReceivedData.forEach((row: any) => {
    //     //   if (
    //     //     row.CalculationType === matchingRowCopy.CalculationType &&
    //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
    //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
    //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
    //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
    //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
    //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
    //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
    //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
    //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
    //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
    //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
    //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
    //     //     row.colChild === matchingRowCopy.colChild &&
    //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
    //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
    //     //     row.rowChild === matchingRowCopy.rowChild &&
    //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
    //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
    //     //   ) {
    //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
    //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
    //     //     row.ColorCode = matchingRowCopy.ColorCode;
    //     //   }
    //     // });

    //     if (typeof window !== 'undefined') {
    //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
    //     }

    //     return this.calculatePercentage(matchingRowCopy);
    //   }
    // }


    return this.calculatePercentage(matchingRow);
  }

  private calculatePercentage(
    matchingRow: CrossTabOutputItem
  ): number | string {


    if (this.selectedDataFormat != 'Respondent Level') {
      //let result: number | string = 'NA';
      //result = `${matchingRow.value}`;
      //return result;
      let calculatedPercentage: number;
      let calcBase: number;
      let calcWeightedBase: number;
      if (matchingRow == undefined) {
        return 'NA';
      }
      let ShareNumber = matchingRow.value;
      let BaseValue = matchingRow.value;
      let WeightedBaseValue = matchingRow.value;

      if (matchingRow) {
        calcBase = BaseValue == null ? 0 : Math.round(BaseValue);
        calcWeightedBase =
          WeightedBaseValue == null ? 0 : Math.round(WeightedBaseValue);
        if (this.isSampleSize) {
          return calcBase !== 0 ? calcBase : 0;
        } else if (this.isWeightedLabel) {
          return calcWeightedBase !== 0 ? calcWeightedBase : 0;
        }
        else {
          let result: number | string = 'NA';

          if (ShareNumber == null) {
            return 'NA';
          }
          calculatedPercentage =(( matchingRow.isDerived && matchingRow.CalculationType.toLowerCase() == 'mean')||(matchingRow.isCustomCalculation)) ? Number(ShareNumber.toFixed(1))
            : Number((ShareNumber * 100).toFixed(1));

          if (!this.isSampleSizeBlock) {
            if ((matchingRow.isDerived && matchingRow.CalculationType.toLowerCase() == "mean")||(matchingRow.isCustomCalculation)) {
              result = `${calculatedPercentage}`;
            }
            else {
              result = `${calculatedPercentage}%`;
            }
            return result;
          }
          else {
            if (calcBase !== 0) {
              if ((matchingRow.isDerived && matchingRow.CalculationType.toLowerCase() == "mean") ||(matchingRow.isCustomCalculation)){
                if (calcBase < this.InsufficientBaseSample) {
                  result = 'I/S';
                }
                else if (calcBase < this.LowBaseSample) {
                  result = `${calculatedPercentage} *`;
                }
                else {
                  result = `${calculatedPercentage}`;
                }
              }
              else {
                if (calcBase < this.InsufficientBaseSample) {
                  result = 'I/S';
                }
                else if (calcBase < this.LowBaseSample) {
                  result = `${calculatedPercentage}% *`;
                }
                else {
                  result = `${calculatedPercentage}% `;
                }
              }
              return result;
            }
            else {
              return 'NA';
            }

          }
        }
      }
    }
    else {

      let calculatedPercentage: number;
      let calcBase: number;
      let SigNumber: number | string;
      let changePy: number | string;
      let calcWeightedBase: number;
      let calcWeightedNumerator: number;
      let calcUnWeightedNumerator: number;
      if (matchingRow == undefined) {
        return 'NA';
      }

      let ShareNumber = (this.selectedDataFormat != 'Respondent Level' ? matchingRow.value : (this.isTotalBase
        ? (this.selectedWeight == "Unweighted" ? matchingRow.totalUnweightedShare : matchingRow.totalWeightedShare)
        : (this.selectedWeight == "Unweighted" ? matchingRow.answerUnweightedShare : matchingRow.answerWeightedShare)));
      let BaseValue = this.isTotalBase
        ? matchingRow.totalUnweightedBase
        : matchingRow.answerUnweightedBase;
      let WeightedBaseValue = this.isTotalBase
        ? (this.selectedWeight == "Unweighted" ? matchingRow.totalUnweightedBase : matchingRow.totalWeightedBase)
        : (this.selectedWeight == "Unweighted" ? matchingRow.answerUnweightedBase : matchingRow.answerWeightedBase);

      let numeratorValue = this.isTotalBase
        ? (matchingRow.CurrentNumeratorWeighted_Total) : (matchingRow.CurrentNumeratorWeighted_Answer);


      let UnWeightednumeratorValue = (this.isTotalBase ? (matchingRow.CurrentNumeratorUnWeighted_Total) : matchingRow.CurrentNumeratorUnWeighted_Answer);

      this.Sig_LowerLimt =
        this.DefaultConfidenceLevel == '95%'
          ? COMMON_CONSTANTS.SigLowerLimit_95
          : this.DefaultConfidenceLevel == '90%'
            ? COMMON_CONSTANTS.SigLowerLimit_90
            : this.DefaultConfidenceLevel == '98%'
              ? COMMON_CONSTANTS.SigLowerLimit_98
              : COMMON_CONSTANTS.SigLowerLimit_95;
      this.Sig_UpperLimt =
        this.DefaultConfidenceLevel == '95%'
          ? COMMON_CONSTANTS.SigUpperLimit_95
          : this.DefaultConfidenceLevel == '90%'
            ? COMMON_CONSTANTS.SigUpperLimit_90
            : this.DefaultConfidenceLevel == '98%'
              ? COMMON_CONSTANTS.SigUpperLimit_98
              : COMMON_CONSTANTS.SigUpperLimit_95;
      let SignificanceValue = this.isTotalBase
        ? matchingRow.Significance_Total
        : matchingRow.Significance_Answer;
      let changevsPY = this.isTotalBase
        ? matchingRow.changeVsPY_Total
        : matchingRow.changeVsPY_Answer;

      // if (ShareNumber == null || BaseValue == null) {
      //   return 'NA';
      // }
      // if (ShareNumber == null || WeightedBaseValue == null) {
      //   return 'NA';
      // }
      if (matchingRow) {
        calcBase = BaseValue == null ? 0 : Math.round(BaseValue);
        calcWeightedBase =
          WeightedBaseValue == null ? 0 : Math.round(WeightedBaseValue);
        calcWeightedNumerator =
          numeratorValue == null ? 0 : Math.round(numeratorValue);
        calcUnWeightedNumerator =
          UnWeightednumeratorValue == null ? 0 : Math.round(UnWeightednumeratorValue);
        if (this.isSampleSize) {
          return calcBase !== 0 ? calcBase : 0;
        } else if (this.isWeightedLabel) {
          return calcWeightedBase !== 0 ? calcWeightedBase : 0;
        }
        else if (this.isWeightedNumerator) {
          return calcWeightedNumerator !== 0 ? calcWeightedNumerator : 0;
        }
        else if (this.isUnWeightedNumerator) {
          return calcUnWeightedNumerator !== 0 ? calcUnWeightedNumerator : 0;
        }
        else {
          let result: number | string = 'NA';

          if (ShareNumber == null) {
            return 'NA';
          }
          calculatedPercentage = (matchingRow.isDerived && this.isIndex && this.selectedIndex != "")
            ? Number((ShareNumber * 100).toFixed(1)) : ((matchingRow.isDerived && matchingRow.CalculationType.toLowerCase() == 'mean' )||((matchingRow.isCustomCalculation)))? Number(ShareNumber.toFixed(1))
              : Number((ShareNumber * 100).toFixed(1));


          if (calcBase !== 0) {
            if (calcBase < this.InsufficientBaseSample) {
              result = 'I/S';
            }
            else {
              SigNumber =
                SignificanceValue == null || isNaN(SignificanceValue)
                  ? 0
                  : Number(SignificanceValue.toFixed(1));
              //changePy =
              //  changevsPY == null
              //    ? 'NA'
              //    : matchingRow.isDerived
              //      ? Number(changevsPY.toFixed(1))
              //      : Number((changevsPY * 100).toFixed(1));

              changePy =
                changevsPY == null
                  ? 'NA'
                  : ((matchingRow.isDerived &&
                      matchingRow.CalculationType.toLowerCase() == 'mean') ||
                    matchingRow.isCustomCalculation)
                  ? Number(changevsPY.toFixed(1))
                  : this.customRound(changevsPY * 100);

              if ((matchingRow.isDerived && matchingRow.CalculationType.toLowerCase() == "mean")||(matchingRow.isCustomCalculation)) {
                if (this.DefaultSampleSize == 'None') {
                  if (calcBase < this.LowBaseSample) {

                    if (this.isIndex && this.selectedIndex != "") {
                      if (calculatedPercentage < (100 - this.ThresholdValue)) {
                        result = `<span class="sigLower"> ${calculatedPercentage} *</span>`;
                      }
                      else if (calculatedPercentage > (100 + this.ThresholdValue)) {
                        result = `<span class="sigHigher"> ${calculatedPercentage} *</span>`;
                      }
                      else {
                        result = `${calculatedPercentage}`;
                      }
                    }
                    else {
                      result = `${calculatedPercentage} *`;
                    }
                  }
                  else {
                    if (this.isIndex && this.selectedIndex != "") {
                      if (calculatedPercentage < (100 - this.ThresholdValue)) {
                        result = `<span class="sigLower"> ${calculatedPercentage} </span>`;
                      }
                      else if (calculatedPercentage > (100 + this.ThresholdValue)) {
                        result = `<span class="sigHigher"> ${calculatedPercentage} </span>`;
                      }
                      else {
                        result = `${calculatedPercentage}`;
                      }
                    }
                    else {
                      result = `${calculatedPercentage}`;
                    }
                  }
                }
                else if (this.DefaultSampleSize == 'Pairwise') {
                  if (calcBase < this.LowBaseSample) {
                    result = `${calculatedPercentage} *`;
                  }
                  else {
                    result = `${calculatedPercentage}`;
                  }
                  let pairwiseText = this.pairWiseText(matchingRow.pairWiseValues);
                  if (pairwiseText != "") {
                    result = `${result} ${pairwiseText}`
                  }
                }
                else {
                  if (calcBase < this.LowBaseSample) {
                    if (changevsPY == null || changePy == "NA") {
                      result = `${calculatedPercentage} * | NA`;
                    }
                    else {
                      if (SigNumber < this.Sig_LowerLimt) {
                        result = `${calculatedPercentage} * |&nbsp; <span class="sigLower"> ${changePy} </span>`;
                      } else if (SigNumber > this.Sig_UpperLimt) {
                        result = `${calculatedPercentage} * |&nbsp; <span class="sigHigher"> ${changePy} </span>`;
                      } else {
                        result = `${calculatedPercentage} * |&nbsp; ${changePy}`;
                      }
                    }
                  }
                  else {
                    if (changevsPY == null || changePy == "NA") {
                      result = `${calculatedPercentage} | NA`;
                    }
                    else {
                      if (SigNumber < this.Sig_LowerLimt) {
                        result = `${calculatedPercentage} |&nbsp; <span class="sigLower"> ${changePy} </span>`;
                      } else if (SigNumber > this.Sig_UpperLimt) {
                        result = `${calculatedPercentage} |&nbsp; <span class="sigHigher"> ${changePy} </span>`;
                      } else {
                        result = `${calculatedPercentage} |&nbsp; ${changePy}`;
                      }
                    }
                  }
                }
              }
              else {
                if (this.DefaultSampleSize == 'None') {
                  if (calcBase < this.LowBaseSample) {
                    if (this.isIndex && this.selectedIndex != "") {
                      if (calculatedPercentage < (100 - this.ThresholdValue)) {
                        result = `<span class="sigLower"> ${calculatedPercentage} *</span>`;
                      }
                      else if (calculatedPercentage > (100 + this.ThresholdValue)) {
                        result = `<span class="sigHigher"> ${calculatedPercentage} *</span>`;
                      }
                      else {
                        result = `${calculatedPercentage} *`;
                      }
                    }
                    else {
                      result = `${calculatedPercentage}% *`;
                    }
                  }
                  else {
                    if (this.isIndex && this.selectedIndex != "") {
                      if (calculatedPercentage < (100 - this.ThresholdValue)) {
                        result = `<span class="sigLower"> ${calculatedPercentage} </span>`;
                      }
                      else if (calculatedPercentage > (100 + this.ThresholdValue)) {
                        result = `<span class="sigHigher"> ${calculatedPercentage} </span>`;
                      }
                      else {
                        result = `${calculatedPercentage}`;
                      }
                    }
                    else {
                      result = `${calculatedPercentage}%`;
                    }
                  }
                }
                else if (this.DefaultSampleSize == 'Pairwise') {
                  if (calcBase < this.LowBaseSample) {
                    result = `${calculatedPercentage}% *`;
                  }
                  else {
                    result = `${calculatedPercentage}%`;
                  }
                  let pairwiseText = this.pairWiseText(matchingRow.pairWiseValues);
                  if (pairwiseText != "") {
                    result = `${result} ${pairwiseText}`
                  }
                }
                else {
                  if (calcBase < this.LowBaseSample) {
                    if (changevsPY == null || changePy == "NA") {
                      result = `${calculatedPercentage}% * | NA`;
                    }
                    else {
                      if (changevsPY == null || changePy == "NA") {
                        result = `${calculatedPercentage}% * |&nbsp; NA`;
                      }
                      else {
                        if (SigNumber < this.Sig_LowerLimt) {
                          result = `${calculatedPercentage}% * |&nbsp; <span class="sigLower"> ${changePy}% </span>`;
                        } else if (SigNumber > this.Sig_UpperLimt) {
                          result = `${calculatedPercentage}% * |&nbsp; <span class="sigHigher"> ${changePy}% </span>`;
                        } else {
                          result = `${calculatedPercentage}% * |&nbsp; ${changePy}%`;
                        }
                      }
                    }
                  }
                  else {
                    if (changevsPY == null || changePy == "NA") {
                      result = `${calculatedPercentage}% |&nbsp; NA`;
                    }
                    else {
                      if (SigNumber < this.Sig_LowerLimt) {
                        result = `${calculatedPercentage}% |&nbsp; <span class="sigLower"> ${changePy}% </span>`;
                      } else if (SigNumber > this.Sig_UpperLimt) {
                        result = `${calculatedPercentage}% |&nbsp; <span class="sigHigher"> ${changePy}% </span>`;
                      } else {
                        result = `${calculatedPercentage}% |&nbsp; ${changePy}%`;
                      }
                    }
                  }
                }

              }
            }
          }
          return result;
        }
      }
      if (ShareNumber == null) {
        return 'NA';
      }
    }
    return 'NA';

  }

  getPercentage_colNest(
    rowItem: any,
    colItem: any,
    colNestItem: any,
    colNestItemLevel2: any
  ): number | string {
    (window as any)['CrossTabData_Index'] = [];
    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) => {
          if (row.isDerived) {
            return (
              row.CalculationType.toLowerCase() == 'mean' &&
              row.colResponseCode == rowItem.responsecode &&
              row.rowResponseCode == colItem.responsecode &&
              row.rowVariableCode == colItem.variablecode &&
              row.Level1RowResponseCode == colNestItem.responsecode &&
              row.Level1NestRowVariableCode == colNestItem.variablecode &&
              row.Level2RowResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == rowItem.Child
            );
          } else {
            return (
              row.colResponseCode == rowItem.responsecode &&
              row.rowResponseCode == colItem.responsecode &&
              row.rowVariableCode == colItem.variablecode &&
              row.Level1RowResponseCode == colNestItem.responsecode &&
              row.Level1NestRowVariableCode == colNestItem.variablecode &&
              row.Level2RowResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == rowItem.Child


            );
          }
        }
      );

      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) => {
          if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
            return (
              row.CalculationType.toLowerCase() == 'mean' &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.Level2ColResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == colItem.Child &&
              row.rowChild == rowItem.Child &&
              row.Level1ColChild == colNestItem.Child &&
              row.Level2ColChild == colNestItemLevel2.Child
            );
          } else if (row.isCustomGroup) {
            return (
              row.isCustomGroup == true &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.Level2ColResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == colItem.Child &&
              (row.customGroupId == rowItem.customGroupId ||
                row.customGroupId == colItem.customGroupId) &&
              row.rowChild == rowItem.Child &&
              row.Level1ColChild == colNestItem.Child &&
              row.Level2ColChild == colNestItemLevel2.Child
            );
          } else if (row.isCustomCalculation) {
            return (
              row.isCustomCalculation == true &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.Level2ColResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == colItem.Child &&
              (row.customCalculationId == rowItem.customCalculationId ||
                row.customCalculationId == colItem.customCalculationId) &&
              row.rowChild == rowItem.Child &&
              row.Level1ColChild == colNestItem.Child &&
              row.Level2ColChild == colNestItemLevel2.Child
            );
          } else {
            return (
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.Level2ColResponseCode == colNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == colNestItemLevel2.variablecode &&
              row.colChild == colItem.Child &&
              row.rowChild == rowItem.Child &&
              row.Level1ColChild == colNestItem.Child &&
              row.Level2ColChild == colNestItemLevel2.Child
            );
          }
        }
      );
      // if (this.isIndex && this.selectedIndex != "") {

      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) => {
      //       if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
      //         return (
      //           colNestItemLevel2 == "" ?
      //             (row.CalculationType.toLowerCase() == 'mean' &&
      //               row.rowResponseCode == rowItem.responsecode &&
      //               row.rowVariableCode == rowItem.variablecode &&
      //               row.colResponseCode == colItem.responsecode &&
      //               row.colVariableCode == colItem.variablecode &&
      //               row.colChild == colItem.Child &&
      //               row.Level1ColChild == this.selectedIndex) : (row.CalculationType.toLowerCase() == 'mean' &&
      //                 row.rowResponseCode == rowItem.responsecode &&
      //                 row.rowVariableCode == rowItem.variablecode &&
      //                 row.colResponseCode == colItem.responsecode &&
      //                 row.colVariableCode == colItem.variablecode &&
      //                 row.Level1ColResponseCode == colNestItem.responsecode &&
      //                 row.Level1NestColVariableCode == colNestItem.variablecode &&
      //                 row.colChild == colItem.Child &&
      //                 row.Level2ColChild == this.selectedIndex
      //             )
      //         );
      //       } else {
      //         return (
      //           colNestItemLevel2 == ""
      //             ?
      //             (row.rowResponseCode == rowItem.responsecode &&
      //               row.rowVariableCode == rowItem.variablecode &&
      //               row.colResponseCode == colItem.responsecode &&
      //               row.colVariableCode == colItem.variablecode &&
      //               row.colChild == colItem.Child &&
      //               row.Level1ColChild == this.selectedIndex) : (row.rowResponseCode == rowItem.responsecode &&
      //                 row.rowVariableCode == rowItem.variablecode &&
      //                 row.colResponseCode == colItem.responsecode &&
      //                 row.colVariableCode == colItem.variablecode &&
      //                 row.Level1ColResponseCode == colNestItem.responsecode &&
      //                 row.Level1NestColVariableCode == colNestItem.variablecode &&
      //                 row.colChild == colItem.Child && row.Level2ColChild == this.selectedIndex)

      //         );
      //       }
      //     }
      //   );
      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;


      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;

      //     }

      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);

      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage = (matchingRowCopy.isDerived && this.isIndex && this.selectedIndex != "")
      //         ? Number((ShareNumber * 100).toFixed(1)) : ((matchingRowCopy.isDerived && matchingRowCopy.CalculationType == 'mean')||((matchingRow.isCustomCalculation))) ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     // let fountItem=this.crossTabReceivedData.find((row: any) => {
      //     //   return row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
            
      //     // });
      //     if(matchingRow!=null)
      //     {
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //     // this.crossTabReceivedData.forEach((row: any) => {
      //     //   if (
      //     //     row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     //   ) {
      //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //     row.ColorCode = matchingRowCopy.ColorCode;
      //     //   }
      //     // });

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }
      //     return this.calculatePercentage(matchingRowCopy);
      //   }
      // }
      return this.calculatePercentage(matchingRow);
    }
  }

  getPercentage_RowNest(
    rowItem: any,
    RowlNestItem: any,
    RowNestItemLevel2: any,
    colItem: any
  ): number | string {
    (window as any)['CrossTabData_Index'] = [];
    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) => {
          if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
            return (
              row.CalculationType.toLowerCase() == 'mean' &&
              row.colResponseCode == rowItem.responsecode &&
              row.colVariableCode == rowItem.variablecode &&
              row.Level1ColResponseCode == RowlNestItem.responsecode &&
              row.Level1NestColVariableCode == RowlNestItem.variablecode &&
              row.Level2ColResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == RowNestItemLevel2.variablecode &&
              row.rowResponseCode == colItem.responsecode &&
              row.rowVariableCode == colItem.variablecode
            );
          } else {
            return (
              row.colResponseCode == rowItem.responsecode &&
              row.rowResponseCode == colItem.responsecode &&
              row.Level1ColResponseCode == RowlNestItem.responsecode &&
              row.colVariableCode == rowItem.variablecode &&
              row.rowVariableCode == colItem.variablecode &&
              row.Level1NestColVariableCode == RowlNestItem.variablecode &&
              row.Level2ColResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestColVariableCode == RowNestItemLevel2.variablecode
            );
          }
        }
      );

      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) => {
          if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
            return (
              row.CalculationType.toLowerCase() == 'mean' &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.rowChild == rowItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.Level2RowChild == RowNestItemLevel2.Child &&
              row.colVariableCode == colItem.variablecode
            );
          }
          else if (row.isCustomGroup) {
            return (
              row.isCustomGroup == true &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.rowChild == rowItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.Level2RowChild == RowNestItemLevel2.Child &&
              (row.customGroupId == rowItem.customGroupId ||
                row.customGroupId == colItem.customGroupId)
            );
          } else if (row.isCustomCalculation) {
            return (
              row.isCustomCalculation == true &&
              row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.rowChild == rowItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.Level2RowChild == RowNestItemLevel2.Child &&
              (row.customCalculationId == rowItem.customCalculationId ||
                row.customCalculationId == colItem.customCalculationId)
            );
          } else {
            return (
              row.rowResponseCode == rowItem.responsecode &&
              row.colResponseCode == colItem.responsecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
              row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
              row.rowChild == rowItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.Level2RowChild == RowNestItemLevel2.Child
            );
          }
        }
      );


      // if (this.isIndex && this.selectedIndex != "") {
      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) => {
      //       if (row.isDerived && row.CalculationType.toLowerCase() == 'mean') {
      //         return (
      //           row.CalculationType.toLowerCase() == 'mean' &&
      //           row.rowResponseCode == rowItem.responsecode &&
      //           row.rowVariableCode == rowItem.variablecode &&
      //           row.Level1RowResponseCode == RowlNestItem.responsecode &&
      //           row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
      //           row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
      //           row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
      //           row.colChild == this.selectedIndex
      //         );
      //       } else {
      //         return (
      //           row.rowResponseCode == rowItem.responsecode &&
      //           row.Level1RowResponseCode == RowlNestItem.responsecode &&
      //           row.rowVariableCode == rowItem.variablecode &&
      //           row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
      //           row.Level2RowResponseCode == RowNestItemLevel2.responsecode &&
      //           row.Level2NestRowVariableCode == RowNestItemLevel2.variablecode &&
      //           row.colChild == this.selectedIndex
      //         );
      //       }
      //     }
      //   );

      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;


      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;


      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;
      //     }

      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);
      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage =
      //         matchingRowCopy.isDerived &&
      //         this.isIndex &&
      //         this.selectedIndex != ''
      //           ? Number((ShareNumber * 100).toFixed(1))
      //           : (matchingRowCopy.isDerived &&
      //               matchingRowCopy.CalculationType == 'mean') ||
      //             matchingRow.isCustomCalculation
      //           ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     if(matchingRow!=null){
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //   //  let foundItem= this.crossTabReceivedData.find((row: any) => {
      //   //     return row.CalculationType === matchingRowCopy.CalculationType &&
      //   //       row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //   //       row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //   //       row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //   //       row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //   //       row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //   //       row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //   //       row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //   //       row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //   //       row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //   //       row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //   //       row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //   //       row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //   //       row.colChild === matchingRowCopy.colChild &&
      //   //       row.colResponseCode === matchingRowCopy.colResponseCode &&
      //   //       row.colVariableCode === matchingRowCopy.colVariableCode &&
      //   //       row.rowChild === matchingRowCopy.rowChild &&
      //   //       row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //   //       row.rowVariableCode === matchingRowCopy.rowVariableCode
            
      //   //   });
      //   //   if(foundItem!=null){
      //   //     foundItem.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //   //     foundItem.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //   //     foundItem.ColorCode = matchingRowCopy.ColorCode;
      //   //   }
      //     // this.crossTabReceivedData.forEach((row: any) => {
      //     //   if (
      //     //     row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     //   ) {
      //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //     row.ColorCode = matchingRowCopy.ColorCode;
      //     //   }
      //     // });

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }
      //     return this.calculatePercentage(matchingRowCopy);


      //   }
      // }
      return this.calculatePercentage(matchingRow);
    }
  }

  getPercentage_multiLevel(
    rowItem: any,
    RowlNestItem: any,
    colItem: any,
    colNestItem: any
  ): number | string {
    //Find the row that matches the response codes

    (window as any)['CrossTabData_Index'] = [];

    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.colResponseCode == rowItem.responsecode &&
            row.colVariableCode == rowItem.variablecode &&
            row.Level1ColResponseCode == RowlNestItem.responsecode &&
            row.Level1NestColVariableCode == RowlNestItem.variablecode &&
            row.rowResponseCode == colItem.responsecode &&
            row.rowVariableCode == colItem.variablecode &&
            row.Level1RowResponseCode == colNestItem.responsecode &&
            row.Level1NestRowVariableCode == colNestItem.variablecode &&
            row.CalculationType.toLowerCase() == 'mean'
            : row.colResponseCode == rowItem.responsecode &&
            row.Level1ColResponseCode == RowlNestItem.responsecode &&
            row.rowResponseCode == colItem.responsecode &&
            row.Level1RowResponseCode == colNestItem.responsecode &&
            row.colVariableCode == rowItem.variablecode &&
            row.rowVariableCode == colItem.variablecode &&
            row.Level1NestColVariableCode == RowlNestItem.variablecode &&
            row.Level1NestRowVariableCode == colNestItem.variablecode
      );
      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == rowItem.responsecode &&
            row.rowVariableCode == rowItem.variablecode &&
            row.Level1RowResponseCode == RowlNestItem.responsecode &&
            row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
            row.colResponseCode == colItem.responsecode &&
            row.colVariableCode == colItem.variablecode &&
            row.Level1ColResponseCode == colNestItem.responsecode &&
            row.Level1NestColVariableCode == colNestItem.variablecode &&
            row.CalculationType.toLowerCase() == 'mean' && row.rowChild == rowItem.Child &&
            row.Level1RowChild == RowlNestItem.Child &&
            row.colChild == colItem.Child &&
            row.Level1ColChild == colNestItem.Child
            :
            row.isCustomGroup
              ? row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.isCustomGroup == true &&
              (row.customGroupId == rowItem.customGroupId || row.customGroupId == colItem.customGroupId) &&
              row.rowChild == rowItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1ColChild == colNestItem.Child :

              row.isCustomCalculation
              ? row.rowResponseCode == rowItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.colResponseCode == colItem.responsecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.isCustomCalculation == true &&
              (row.customCalculationId == rowItem.customCalculationId || row.customCalculationId == colItem.customCalculationId) &&
              row.rowChild == rowItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1ColChild == colNestItem.Child:

              row.rowResponseCode == rowItem.responsecode &&
              row.Level1RowResponseCode == RowlNestItem.responsecode &&
              row.colResponseCode == colItem.responsecode &&
              row.Level1ColResponseCode == colNestItem.responsecode &&
              row.rowVariableCode == rowItem.variablecode &&
              row.colVariableCode == colItem.variablecode &&
              row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
              row.Level1NestColVariableCode == colNestItem.variablecode &&
              row.rowChild == rowItem.Child &&
              row.Level1RowChild == RowlNestItem.Child &&
              row.colChild == colItem.Child &&
              row.Level1ColChild == colNestItem.Child
      );

      // if (this.isIndex && this.selectedIndex != "") {
      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) =>
      //       row.isDerived && row.CalculationType.toLowerCase() == 'mean'
      //         ? row.rowResponseCode == rowItem.responsecode &&
      //         row.rowVariableCode == rowItem.variablecode &&
      //         row.Level1RowResponseCode == RowlNestItem.responsecode &&
      //         row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
      //         row.colResponseCode == colItem.responsecode &&
      //         row.colVariableCode == colItem.variablecode &&
      //         row.Level1ColChild == this.selectedIndex &&
      //         row.CalculationType.toLowerCase() == 'mean'
      //         : row.rowResponseCode == rowItem.responsecode &&
      //         row.Level1RowResponseCode == RowlNestItem.responsecode &&
      //         row.colResponseCode == colItem.responsecode &&
      //         row.rowVariableCode == rowItem.variablecode &&
      //         row.colVariableCode == colItem.variablecode &&
      //         row.Level1NestRowVariableCode == RowlNestItem.variablecode &&
      //         row.Level1ColChild == this.selectedIndex
      //   );

      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;
      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;
      //     }

      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);
      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage =
      //         matchingRowCopy.isDerived &&
      //         this.isIndex &&
      //         this.selectedIndex != ''
      //           ? Number((ShareNumber * 100).toFixed(1))
      //           : (matchingRowCopy.isDerived &&
      //               matchingRowCopy.CalculationType == 'mean') ||
      //             matchingRow.isCustomCalculation
      //           ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     if(matchingRow!=null){
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //   //  let foundItem= this.crossTabReceivedData.find((row: any) => {
      //   //     return row.CalculationType === matchingRowCopy.CalculationType &&
      //   //       row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //   //       row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //   //       row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //   //       row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //   //       row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //   //       row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //   //       row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //   //       row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //   //       row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //   //       row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //   //       row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //   //       row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //   //       row.colChild === matchingRowCopy.colChild &&
      //   //       row.colResponseCode === matchingRowCopy.colResponseCode &&
      //   //       row.colVariableCode === matchingRowCopy.colVariableCode &&
      //   //       row.rowChild === matchingRowCopy.rowChild &&
      //   //       row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //   //       row.rowVariableCode === matchingRowCopy.rowVariableCode
            
      //   //   });
      //   //   if(foundItem!=null){
      //   //     foundItem.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //   //     foundItem.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //   //     foundItem.ColorCode = matchingRowCopy.ColorCode;
      //   //   }
      //     // this.crossTabReceivedData.forEach((row: any) => {
      //     //   if (
      //     //     row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     //   ) {
      //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //     row.ColorCode = matchingRowCopy.ColorCode;
      //     //   }
      //     // });

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }
      //     return this.calculatePercentage(matchingRowCopy);
      //   }
      // }
      return this.calculatePercentage(matchingRow);
    }
  }

  getPercentage_multiLevel_level2(
    rowLevel0: any,
    rowLevel1: any,
    rowLevel2: any,
    colLevel0: any,
    colLevel1: any,
    colLevel2: any
  ): number | string {
    //Find the row that matches the response codes
    (window as any)['CrossTabData_Index'] = [];

    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == colLevel0.responsecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.Level2RowResponseCode == colLevel2.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.Level2NestColVariableCode == rowLevel2.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode &&
            row.Level2NestRowVariableCode == colLevel2.variablecode &&
            row.CalculationType.toLowerCase() == 'mean'
            : row.colResponseCode == rowLevel0.responsecode &&
            row.Level1ColResponseCode == rowLevel1.responsecode &&
            row.Level2ColResponseCode == rowLevel2.responsecode &&
            row.rowResponseCode == colLevel0.responsecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.Level2RowResponseCode == colLevel2.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.Level2NestColVariableCode == rowLevel2.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode &&
            row.Level2NestRowVariableCode == colLevel2.variablecode
      );
      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == rowLevel0.responsecode &&
            row.rowVariableCode == rowLevel0.variablecode &&
            row.Level1RowResponseCode == rowLevel1.responsecode &&
            row.Level1NestRowVariableCode == rowLevel1.variablecode &&
            row.Level2RowResponseCode == rowLevel2.responsecode &&
            row.Level2NestRowVariableCode == rowLevel2.variablecode &&
            row.colResponseCode == colLevel0.responsecode &&
            row.colVariableCode == colLevel0.variablecode &&
            row.Level1ColResponseCode == colLevel1.responsecode &&
            row.Level1NestColVariableCode == colLevel1.variablecode &&
            row.Level2ColResponseCode == colLevel2.responsecode &&
            row.Level2NestColVariableCode == colLevel2.variablecode &&
            row.CalculationType.toLowerCase() == 'mean' &&
            row.rowChild == rowLevel0.Child &&
            row.Level1RowChild == rowLevel1.Child &&
            row.Level2RowChild == rowLevel2.Child &&
            row.colChild == colLevel0.Child &&
            row.Level1ColChild == colLevel1.Child &&
            row.Level2ColChild == colLevel2.Child :
            row.isCustomGroup
              ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.isCustomGroup == true && (row.customGroupId == rowLevel0.customGroupId || row.customGroupId == colLevel2.customGroupId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child :
            row.isCustomCalculation ?
             row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.isCustomCalculation == true && (row.customCalculationId == rowLevel0.customCalculationId || row.customCalculationId == colLevel2.customCalculationId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
              : row.rowResponseCode == rowLevel0.responsecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
      );


      // if (this.isIndex && this.selectedIndex != "") {

      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) =>
      //       row.isDerived && row.CalculationType.toLowerCase() == 'mean'
      //         ? row.rowResponseCode == rowLevel0.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.Level2RowResponseCode == rowLevel2.responsecode &&
      //         row.Level2NestRowVariableCode == rowLevel2.variablecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.Level1ColResponseCode == colLevel1.responsecode &&
      //         row.Level1NestColVariableCode == colLevel1.variablecode &&
      //         row.Level2ColChild == this.selectedIndex &&
      //         row.CalculationType.toLowerCase() == 'mean'
      //         : row.rowResponseCode == rowLevel0.responsecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level2RowResponseCode == rowLevel2.responsecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.Level1ColResponseCode == colLevel1.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.Level2NestRowVariableCode == rowLevel2.variablecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.Level1NestColVariableCode == colLevel1.variablecode &&
      //         row.Level2ColChild == this.selectedIndex
      //   );

      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;

      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;
      //     }
      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);
      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage =
      //         matchingRowCopy.isDerived &&
      //         this.isIndex &&
      //         this.selectedIndex != ''
      //           ? Number((ShareNumber * 100).toFixed(1))
      //           : (matchingRowCopy.isDerived &&
      //               matchingRowCopy.CalculationType == 'mean') ||
      //             matchingRow.isCustomCalculation
      //           ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     if(matchingRow!=null){
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //     // let foundItem=this.crossTabReceivedData.find((row: any) => {
      //     //  return row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     // });
      //     // if(foundItem!=null){
      //     //   foundItem.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //   foundItem.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //   foundItem.ColorCode = matchingRowCopy.ColorCode;
      //     // }

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }
      //     return this.calculatePercentage(matchingRowCopy);
      //   }
      // }

      return this.calculatePercentage(matchingRow);
    }
  }

  getPercentage_multiLevel_Row_Level1(
    rowLevel0: any,
    rowLevel1: any,
    rowLevel2: any,
    colLevel0: any,
    colLevel1: any
  ): number | string {
    //Find the row that matches the response codes

    (window as any)['CrossTabData_Index'] = [];

    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == colLevel0.responsecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.Level2NestColVariableCode == rowLevel2.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode &&
            row.CalculationType.toLowerCase() == 'mean'
            : row.colResponseCode == rowLevel0.responsecode &&
            row.Level1ColResponseCode == rowLevel1.responsecode &&
            row.Level2ColResponseCode == rowLevel2.responsecode &&
            row.rowResponseCode == colLevel0.responsecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.Level2NestColVariableCode == rowLevel2.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode
      );
      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.CalculationType.toLowerCase() == 'mean' &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child
            : row.isCustomGroup
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.isCustomGroup == true &&
              (row.customGroupId == rowLevel0.customGroupId ||
                row.customGroupId == colLevel1.customGroupId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child
            : row.isCustomCalculation
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.isCustomCalculation == true &&
              (row.customCalculationId == rowLevel0.customCalculationId ||
                row.customCalculationId == colLevel1.customCalculationId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child
            :
             row.rowResponseCode == rowLevel0.responsecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level2RowResponseCode == rowLevel2.responsecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level2NestRowVariableCode == rowLevel2.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.Level2RowChild == rowLevel2.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child
      );
      // if (this.isIndex && this.selectedIndex != "") {
      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) =>
      //       row.isDerived && row.CalculationType.toLowerCase() == 'mean'
      //         ? row.rowResponseCode == rowLevel0.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.Level2RowResponseCode == rowLevel2.responsecode &&
      //         row.Level2NestRowVariableCode == rowLevel2.variablecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.Level1ColChild == this.selectedIndex &&
      //         row.CalculationType.toLowerCase() == 'mean'
      //         : row.rowResponseCode == rowLevel0.responsecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level2RowResponseCode == rowLevel2.responsecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.Level2NestRowVariableCode == rowLevel2.variablecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.Level1ColChild == this.selectedIndex
      //   );

      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;

      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;

      //     }
      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);
      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage =
      //         matchingRowCopy.isDerived &&
      //         this.isIndex &&
      //         this.selectedIndex != ''
      //           ? Number((ShareNumber * 100).toFixed(1))
      //           : (matchingRowCopy.isDerived &&
      //               matchingRowCopy.CalculationType == 'mean') ||
      //             matchingRow.isCustomCalculation
      //           ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     if(matchingRow!=null){
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //     // let foundItem=this.crossTabReceivedData.find((row: any) => {
      //     //   return row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     // });
      //     // if(foundItem!=null){
      //     //   foundItem.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //   foundItem.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //   foundItem.ColorCode = matchingRowCopy.ColorCode;
      //     // }

      //     // this.crossTabReceivedData.forEach((row: any) => {
      //     //   if (
      //     //     row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     //   ) {
      //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //     row.ColorCode = matchingRowCopy.ColorCode;
      //     //   }
      //     // });

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }

      //     return this.calculatePercentage(matchingRowCopy);
      //   }
      // }

      return this.calculatePercentage(matchingRow);
    }
  }


  getPercentage_multiLevel_rowLevel1_columnLevel2(
    rowLevel0: any,
    rowLevel1: any,
    colLevel0: any,
    colLevel1: any,
    colLevel2: any
  ): number | string {
    //Find the row that matches the response codes
    (window as any)['CrossTabData_Index'] = [];
    if (this.isTranspose) {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ?
            row.colResponseCode == rowLevel0.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.rowResponseCode == colLevel0.responsecode &&
            row.Level1ColResponseCode == rowLevel1.responsecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode &&
            row.Level2RowResponseCode == colLevel2.responsecode &&
            row.Level2NestRowVariableCode == colLevel2.variablecode &&
            row.CalculationType.toLowerCase() == 'mean'
            : row.colResponseCode == rowLevel0.responsecode &&
            row.colVariableCode == rowLevel0.variablecode &&
            row.Level1ColResponseCode == rowLevel1.responsecode &&
            row.Level1NestColVariableCode == rowLevel1.variablecode &&
            row.rowVariableCode == colLevel0.variablecode &&
            row.rowResponseCode == colLevel0.responsecode &&
            row.Level1RowResponseCode == colLevel1.responsecode &&
            row.Level1NestRowVariableCode == colLevel1.variablecode &&
            row.Level2RowResponseCode == colLevel2.responsecode &&
            row.Level2NestRowVariableCode == colLevel2.variablecode
      );
      return this.calculatePercentage(matchingRow);
    } else {
      const matchingRow = this.crossTabReceivedData.find(
        (row: CrossTabOutputItem) =>
          row.isDerived && row.CalculationType.toLowerCase() == 'mean'
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.CalculationType.toLowerCase() == 'mean' &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
            : row.isCustomGroup
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.isCustomGroup == true &&
              (row.customGroupId == rowLevel0.customGroupId ||
                row.customGroupId == colLevel2.customGroupId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
            : row.isCustomCalculation
            ? row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.isCustomCalculation == true &&
              (row.customCalculationId == rowLevel0.customCalculationId ||
                row.customCalculationId == colLevel2.customCalculationId) &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
            : row.rowResponseCode == rowLevel0.responsecode &&
              row.rowVariableCode == rowLevel0.variablecode &&
              row.Level1RowResponseCode == rowLevel1.responsecode &&
              row.Level1NestRowVariableCode == rowLevel1.variablecode &&
              row.colVariableCode == colLevel0.variablecode &&
              row.colResponseCode == colLevel0.responsecode &&
              row.Level1ColResponseCode == colLevel1.responsecode &&
              row.Level1NestColVariableCode == colLevel1.variablecode &&
              row.Level2ColResponseCode == colLevel2.responsecode &&
              row.Level2NestColVariableCode == colLevel2.variablecode &&
              row.rowChild == rowLevel0.Child &&
              row.Level1RowChild == rowLevel1.Child &&
              row.colChild == colLevel0.Child &&
              row.Level1ColChild == colLevel1.Child &&
              row.Level2ColChild == colLevel2.Child
      );

      // if (this.isIndex && this.selectedIndex != "") {
      //   const matchingReferenceRow = this.crossTabReceivedData.find(
      //     (row: CrossTabOutputItem) =>
      //       row.isDerived && row.CalculationType.toLowerCase() == 'mean'
      //         ? row.rowResponseCode == rowLevel0.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.Level1ColResponseCode == colLevel1.responsecode &&
      //         row.Level1NestColVariableCode == colLevel1.variablecode &&
      //         row.Level2ColChild == this.selectedIndex &&
      //         row.CalculationType.toLowerCase() == 'mean'
      //         : row.rowResponseCode == rowLevel0.responsecode &&
      //         row.rowVariableCode == rowLevel0.variablecode &&
      //         row.Level1RowResponseCode == rowLevel1.responsecode &&
      //         row.Level1NestRowVariableCode == rowLevel1.variablecode &&
      //         row.colVariableCode == colLevel0.variablecode &&
      //         row.colResponseCode == colLevel0.responsecode &&
      //         row.Level1ColResponseCode == colLevel1.responsecode &&
      //         row.Level1NestColVariableCode == colLevel1.variablecode &&
      //         row.Level2ColChild == this.selectedIndex
      //   );

      //   if (matchingReferenceRow != undefined) {
      //     const matchingRowCopy = { ...matchingRow };
      //     const matchingReferenceRowCopy = { ...matchingReferenceRow };

      //     if (this.selectedWeight == "Unweighted") {
      //       matchingRowCopy.totalUnweightedShare = isNaN(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? null : isFinite(matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) ? (matchingRowCopy.totalUnweightedShare / matchingReferenceRowCopy.totalUnweightedShare) : null;
      //       matchingRowCopy.answerUnweightedShare = isNaN(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? null : isFinite(matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) ? (matchingRowCopy.answerUnweightedShare / matchingReferenceRowCopy.answerUnweightedShare) : null;

      //       matchingRowCopy.totalUnweightedShare_Index = matchingRowCopy.totalUnweightedShare;
      //       matchingRowCopy.answerUnweightedShare_Index = matchingRowCopy.answerUnweightedShare;

      //     }
      //     else {
      //       matchingRowCopy.totalWeightedShare = isNaN(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? null : isFinite(matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) ? (matchingRowCopy.totalWeightedShare / matchingReferenceRowCopy.totalWeightedShare) : null;
      //       matchingRowCopy.answerWeightedShare = isNaN(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? null : isFinite(matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) ? (matchingRowCopy.answerWeightedShare / matchingReferenceRowCopy.answerWeightedShare) : null;

      //       matchingRowCopy.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare;
      //       matchingRowCopy.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare;
      //     }
      //     let calculatedPercentage: number;
      //     let ShareNumber = this.isTotalBase
      //       ? (this.selectedWeight == "Unweighted" ? matchingRowCopy.totalUnweightedShare_Index : matchingRowCopy.totalWeightedShare_Index)
      //       : (this.selectedWeight == "Unweighted" ? matchingRowCopy.answerUnweightedShare_Index : matchingRowCopy.answerWeightedShare_Index);
      //     if (ShareNumber == null) {
      //       matchingRowCopy.ColorCode = "black";
      //       return 'NA';
      //     }
      //     else {
      //       calculatedPercentage =
      //         matchingRowCopy.isDerived &&
      //         this.isIndex &&
      //         this.selectedIndex != ''
      //           ? Number((ShareNumber * 100).toFixed(1))
      //           : (matchingRowCopy.isDerived &&
      //               matchingRowCopy.CalculationType == 'mean') ||
      //             matchingRow.isCustomCalculation
      //           ? Number(ShareNumber.toFixed(1))
      //           : Number((ShareNumber * 100).toFixed(1));

      //       if (calculatedPercentage < (100 - this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "red";
      //       }
      //       else if (calculatedPercentage > (100 + this.ThresholdValue)) {
      //         matchingRowCopy.ColorCode = "green";
      //       }
      //       else {
      //         matchingRowCopy.ColorCode = "black";
      //       }
      //     }
      //     if(matchingRow!=null){
      //       matchingRow.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //       matchingRow.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //       matchingRow.ColorCode = matchingRowCopy.ColorCode;
      //     }
      //     // let foundItem=this.crossTabReceivedData.find((row: any) => {
      //     //  return row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
            
      //     // });
      //     // if(foundItem!=null){
      //     //   foundItem.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //   foundItem.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //   foundItem.ColorCode = matchingRowCopy.ColorCode;
      //     // }

      //     // this.crossTabReceivedData.forEach((row: any) => {
      //     //   if (
      //     //     row.CalculationType === matchingRowCopy.CalculationType &&
      //     //     row.Level1ColChild === matchingRowCopy.Level1ColChild &&
      //     //     row.Level1ColResponseCode === matchingRowCopy.Level1ColResponseCode &&
      //     //     row.Level1NestColVariableCode === matchingRowCopy.Level1NestColVariableCode &&
      //     //     row.Level1NestRowVariableCode === matchingRowCopy.Level1NestRowVariableCode &&
      //     //     row.Level1RowChild === matchingRowCopy.Level1RowChild &&
      //     //     row.Level1RowResponseCode === matchingRowCopy.Level1RowResponseCode &&
      //     //     row.Level2ColChild === matchingRowCopy.Level2ColChild &&
      //     //     row.Level2ColResponseCode === matchingRowCopy.Level2ColResponseCode &&
      //     //     row.Level2NestColVariableCode === matchingRowCopy.Level2NestColVariableCode &&
      //     //     row.Level2NestRowVariableCode === matchingRowCopy.Level2NestRowVariableCode &&
      //     //     row.Level2RowChild === matchingRowCopy.Level2RowChild &&
      //     //     row.Level2RowResponseCode === matchingRowCopy.Level2RowResponseCode &&
      //     //     row.colChild === matchingRowCopy.colChild &&
      //     //     row.colResponseCode === matchingRowCopy.colResponseCode &&
      //     //     row.colVariableCode === matchingRowCopy.colVariableCode &&
      //     //     row.rowChild === matchingRowCopy.rowChild &&
      //     //     row.rowResponseCode === matchingRowCopy.rowResponseCode &&
      //     //     row.rowVariableCode === matchingRowCopy.rowVariableCode
      //     //   ) {
      //     //     row.totalWeightedShare_Index = matchingRowCopy.totalWeightedShare_Index;
      //     //     row.answerWeightedShare_Index = matchingRowCopy.answerWeightedShare_Index;
      //     //     row.ColorCode = matchingRowCopy.ColorCode;
      //     //   }
      //     // });

      //     if (typeof window !== 'undefined') {
      //       (window as any)['CrossTabData_Index'] = _.cloneDeep(this.crossTabReceivedData);
      //     }

      //     return this.calculatePercentage(matchingRowCopy);
      //   }
      // }
      return this.calculatePercentage(matchingRow);
    }
  }

  pairWiseText(pairWiseValues: PairWiseDetails[]) {
    let html = "";
    if (pairWiseValues != null && pairWiseValues.length > 0) {
      html = " | ";
      pairWiseValues.forEach((x: PairWiseDetails) => {
        html = ` ${html} <span class='${x.color}'>${x.pairWiseAlph}</span>`
      })
    }
    return html;
  }

  customRound(num: number): number {
    //if (num == -1.7041564866026857) {
    //  num = -1.7641564866026857;
    //  const roundedValue = Math.round(num * 10) / 10; // Round to one decimal place
    //  return roundedValue;
    //}
    //else {
    const roundedValue = Math.round(num * 10) / 10; // Round to one decimal place
    return roundedValue;
    // }
  }
}
