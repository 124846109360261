import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/app/environments/environment';
@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.css']
})
export class HeaderHomeComponent {
  toggleSetting: boolean = false;
  @Input() settingIconShown: boolean = true;
  guideUrl = environment.UserGuideUrl;
  constructor(private router: Router, private _authenticateService: AuthenticationService) { }

  redirectToLandingPage() {
    this.router.navigate(['/landing']);
  }
  onUserManagementClick() {
    this.router.navigate(['/user-management']);
  }
  onUserGuideClick() {
    const guideUrl = this.guideUrl;
    window.open(guideUrl, '_blank');
  }
  LogOut() {
    this._authenticateService.logout();
  }
  OnSettingClick() {
    this.toggleSetting = !this.toggleSetting;
  }
  closeSummary(){
    ;
    this.toggleSetting=false;
  }
}
