<div class="home-block">
  <!-- <div class="home-block-header">Powered by <b>KANTAR</b></div> -->
  <div class="setting-block-header"></div>

  <div class="setting-icon" *ngIf="settingIconShown">
    <img src="../../../../assets/images/common/Settings Icon.svg" title="setting" alt="setting"
      (click)="OnSettingClick()"  DocumentClick (DocumentClicked)="closeSummary()"/>
    <div class="setting-container" *ngIf="toggleSetting" >

      <div class="setting-blocks" title="Help Guide" (click)="onUserGuideClick()">
        <div class="setting-block-icon user-guide"></div>
        <div class="setting-block-name">Help Guide</div>
      </div>

      <div class="setting-blocks"
           title="Document Repository">
        <div class="setting-block-icon doc-repo-icon"></div>
        <div class="setting-block-name">Document Repository</div>
      </div>

      <div class="setting-blocks" title="User Management"
        (click)="onUserManagementClick()">
        <div class="setting-block-icon user-management-icon"></div>
        <div class="setting-block-name">User Management</div>
      </div>

      <div class="setting-blocks" title="Log out" (click)="LogOut()">
        <div class="setting-block-icon logout-icon"></div>
        <div class="setting-block-name">Log out</div>
      </div>
    </div>
  </div>

  <div class="home-icon" title="Home" (click)="redirectToLandingPage()">
    <img src="../../../../assets/images/common/Home Icon.svg" alt="Home">
  </div>
</div>
