export const environment = {
  UserGuideUrl:
    'https://elearning.easygenerator.com/bcd568f0-29e3-43fb-a042-238bfbb04e6e/',
  //baseApiUrl: 'https://localhost:44376/',
  kantarClientID: '5bfc7a38-c638-4049-8f0e-aab51827893c',
  kantarApiClientID: 'eb6c005a-98e4-4375-b3ce-1c11bda51c4b',
  kantarAuthorityID:
    'https://login.microsoftonline.com/1e355c04-e0a4-42ed-8e2d-7351591f0ef1',
  //redirectUrl: 'http://localhost:4200/landing',
  redirectUrl: 'https://dev-dataexplorer.kantar.com/landing',
  baseApiUrl: 'https://kapdashboardapidev.azurewebsites.net/',
  logoutURL: 'https://dev-dataexplorer.kantar.com/signout-oidc',
  baseUrl: '/',
  sessionTimeOut: 40,
};
