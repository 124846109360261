import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllCustomReports, AllSavedSelectionList, CustomReportForDownload, CustomReports, CustomReportsInputs, CustomReportsSavedSelection, DeleteInputs, EmailList, SelectionDetails } from '../../models/custom-report.model';
import { CustomReportService } from '../../services/custom-report.service';
import { AlertMessageService } from '../../services/alert-message.service';
import { LoaderService } from '../../services/loader.service';
import { AlertMessageModel, results } from '../../models/common.model';
import { forEach } from 'lodash';
import { ExcelExportReportInputs } from '../../models/excel-export-report.model';
import { COMMON_CONSTANTS } from '../../constants/common-constants';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.css']
})
export class CustomReportsComponent implements OnInit {
  @Input() client_name: string = "";
  @Input() study_name: string = "";
  @Input() showBrandAnalysis:boolean=false;
  module_id: string = COMMON_CONSTANTS.DataExplorerModuleId.toString();
  brandAnalysisModule_id: string = COMMON_CONSTANTS.BrandAnalysisModuleId.toString();
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  listCustomReportHeader:string="Custom Reports";
  createCustomReportHeader:string="Create Custom Report";
  editCustomReportHeader:string="Edit Custom Report"
  duplicateCustomReportHeader:string="Duplicate Custom Report"
  shareReportHeader:string="Share Custom Report";
  userListHeader:string="User List";
  selectedUserListHeader:string="Selected Email List";
  headerText:string=this.listCustomReportHeader;
  userDefinedHeaderText:string="User Defined";
  sharedWithMeHeaderText:string="Shared With Me";
  datauserDefinedHeaderText:string="Crosstab : User Defined";
  datasharedWithMeHeaderText:string="Crosstab : Shared With Me";
  branduserDefinedHeaderText:string="Brand Analysis : User Defined";
  brandsharedWithMeHeaderText:string="Brand Analysis : Shared With Me";
  userDefinedListHeaders:string[]=["Selections","Download","Info","Edit","Duplicate","Delete","Share"];
  sharedWithMeListHeaders:string[]=["Selections","Download","Info","Delete","Email"];
  userDefinedSearchQuery:string="";
  sharedWithMeSearchQuery:string="";
  showUserDefinedTable:boolean=true;
  showSharedWithMeTable:boolean=false;
  datauserDefinedSearchQuery:string="";
  datasharedWithMeSearchQuery:string="";
  datashowUserDefinedTable:boolean=true;
  datashowSharedWithMeTable:boolean=false;
  branduserDefinedSearchQuery:string="";
  brandsharedWithMeSearchQuery:string="";
  brandshowUserDefinedTable:boolean=false;
  brandshowSharedWithMeTable:boolean=false;
  isCreateEdit:boolean=false;
  isUpdateCustomGroup:boolean=false
  userDefinedReportsList:CustomReports[]=[];
  sharedWithMeReportsList:CustomReports[]=[];
  dataExplorerSavedSelectionList:CustomReportsSavedSelection[]=[];
  dataExplorerSharedWithMeSelectionList:CustomReportsSavedSelection[]=[];
  brandAnalysisSavedSelectionList:CustomReportsSavedSelection[]=[];
  brandAnalysisSharedWithMeSelectionList:CustomReportsSavedSelection[]=[];
  savedSelctionListHeaders:string[]=["Selections","Info"];
  isSaveDisable:boolean=false;
  savePopup:boolean=false;
  
  saveSelectionText:string="Please enter the custom report name (Max 50 characters)";
  saveSelectionHeader:string="Custom Report Name";
  customReportName:string="";
  errorTextSavedSelection:string="Please select atleast one saved selection to create custom report."
  errorTextCustomReportName:string="Custom report name cannot be empty."
  errorTextCustomReportNameExist:string="Custom report name already exist."
  messageTextInsertCustomReport:string="Saved Successfully.";
  messageTextUpdateCustomReport:string="Updated Successfully.";
  messageTextDeleteCustomReport:string="Deleted Successfully.";
  messageTextSharedCustomReport:string="Shared Successfully.";
  messageTextDownloadCustomReport:string="Your download is in progress. Please check the status in Export Downloads";
  customReportInfoHeader:string="Custom Report : Info";
  savedSelectionInfoHeader:string="Selection Summary : Info"
  deleteConfirmationText:string="Are you sure you want to delete this Report?"
  updateConfirmationText:string="Are you sure you want want to update this custom report ?"
  confirmationMessageDownloadNoTimeperiod:string="Are you sure you want want to download this custom report ?"
  confirmationMessageDownload:string="Do you want to update the report to the latest time period?"
  cancelBtnTextForDownload:string="No";
  confirmBtnTextForDownload:string="Yes";
  confirmationMessage:string=""
  customReportInfoList:string[]=[];
  showCustomReportInfo:boolean=false;
  selectedCustomReportItem!:CustomReports;
  userDefinedReportText="userdefined";
  sharedWithMeReportText="sharedwithme";
  customReportType:string="";
  isShowDialog:boolean=false;
  isShowDialogDownload:boolean=false;
  showSavedSelectionInfo:boolean=false;
  selectionSummary:string="";
  showShareSelectionPopup:boolean=false;
  userEmailList:EmailList[]=[];
  filteredEmailList:EmailList[]=[];
  selectedEmailList:EmailList[]=[];
  showExportsDownloadsPopup:boolean=false;
  hasTimePeriodMapping:boolean=false;
  isShowDialogForNoTimePeriod:boolean=false;
  // exportReportInput: ExcelExportReportInputs = {
  //   report_type:'CustomReport',
  //   client_name:'',
  //   study_name:'',
  // };
  constructor(private _customReportService: CustomReportService,
    private _loaderService: LoaderService,
    private _alertService:AlertMessageService,
    ) { 
    
  }
  /**
 * Angular lifecycle hook called after Angular initializes the component.
 * It calls the method to fetch all custom reports when the component is initialized.
 */
ngOnInit(): void {
  // this.exportReportInput.client_name=this.client_name;
  // this.exportReportInput.study_name=this.study_name;
  // Call the method to fetch all custom reports
  this.GetAllCustomReports();
}

  /**
 * Fetches all custom reports from the server and updates the component's reports lists.
 */
GetAllCustomReports(): void {
  // Show loader while fetching data
  this._loaderService.showLoader();
  

  // Retrieve input data for the API call
  let inputData = this.GetInputObject();
  // Call the custom report service to get all custom reports
  this._customReportService.getAllCustomReports(inputData).subscribe((result: results) => {
      // Check if the result has valid data
      if (result.HasValidData) {
          // Parse the JSON data to AllCustomReports object
          let ReportsList: AllCustomReports = JSON.parse(result.Data);
          // Update the component's userDefinedReportsList and sharedWithMeReportsList
          this.userDefinedReportsList = ReportsList.userDefinedReports;
          this.sharedWithMeReportsList = ReportsList.sharedWithMeReports;
          this.hasTimePeriodMapping=ReportsList.hasTimePeriod;
      }
      // Hide loader after fetching data
      this._loaderService.hideLoader();
  });
}

 /**
 * Constructs and returns an input object for custom reports.
 * @returns A CustomReportsInputs object containing client name, module ID, and study name.
 */
GetInputObject(): CustomReportsInputs {
  // Create a new input object with client name, module ID, and study name
  let inputObject: CustomReportsInputs = {
      client_name: this.client_name,
      moduleId: this.module_id,
      study_name: this.study_name,
      brandAnalysisModuleId:this.brandAnalysisModule_id
  };

  // Return the constructed input object
  return inputObject;
}

  /**
 * Toggles the visibility of the user-defined reports table.
 * If the user-defined table is shown, the shared with me table is hidden,
 * and resets the search queries for both tables.
 */
toggleUserDefinedTable(): void {
  // Toggle the visibility of the user-defined table
  this.showUserDefinedTable = !this.showUserDefinedTable;
  // If user-defined table is shown, hide the shared with me table
  if (this.showUserDefinedTable) {
      this.showSharedWithMeTable = false;
  }
  // Reset the search queries for user-defined and shared with me tables
  this.userDefinedSearchQuery = "";
  this.sharedWithMeSearchQuery = "";
}

toggleDataUserDefinedTable(): void {
  // Toggle the visibility of the user-defined table
  this.datashowUserDefinedTable = !this.datashowUserDefinedTable;
  // If user-defined table is shown, hide the shared with me table
  if (this.datashowUserDefinedTable) {
      this.datashowSharedWithMeTable = false;
      this.brandshowSharedWithMeTable=false;
      this.brandshowUserDefinedTable=false;
  }
  // Reset the search queries for user-defined and shared with me tables
  this.datauserDefinedSearchQuery = "";
  this.datasharedWithMeSearchQuery = "";
  this.branduserDefinedSearchQuery = "";
  this.brandsharedWithMeSearchQuery = "";
}
toggleBrandUserDefinedTable(): void {
  // Toggle the visibility of the user-defined table
  this.brandshowUserDefinedTable = !this.brandshowUserDefinedTable;
  // If user-defined table is shown, hide the shared with me table
  if (this.brandshowUserDefinedTable) {
      this.datashowSharedWithMeTable = false;
      this.brandshowSharedWithMeTable=false;
      this.datashowUserDefinedTable=false;
  }
  // Reset the search queries for user-defined and shared with me tables
  this.datauserDefinedSearchQuery = "";
  this.datasharedWithMeSearchQuery = "";
  this.branduserDefinedSearchQuery = "";
  this.brandsharedWithMeSearchQuery = "";
}



  /**
 * Toggles the visibility of the shared-with-me reports table.
 * If the shared-with-me table is shown, the user-defined table is hidden,
 * and resets the search queries for both tables.
 */
toggleSharedWithMeTable(): void {
  // Toggle the visibility of the shared-with-me table
  this.showSharedWithMeTable = !this.showSharedWithMeTable;

  // If shared-with-me table is shown, hide the user-defined table
  if (this.showSharedWithMeTable) {
      this.showUserDefinedTable = false;
  }

  // Reset the search queries for user-defined and shared-with-me tables
  this.userDefinedSearchQuery = "";
  this.sharedWithMeSearchQuery = "";
}

toggleDataSharedWithMeTable(): void {
  // Toggle the visibility of the shared-with-me table
  this.datashowSharedWithMeTable = !this.datashowSharedWithMeTable;

  // If shared-with-me table is shown, hide the user-defined table
  if (this.datashowSharedWithMeTable) {
      this.datashowUserDefinedTable = false;
      this.brandshowSharedWithMeTable=false;
      this.brandshowUserDefinedTable=false;
  }

  // Reset the search queries for user-defined and shared-with-me tables
  this.datauserDefinedSearchQuery = "";
  this.datasharedWithMeSearchQuery = "";
  this.branduserDefinedSearchQuery = "";
  this.brandsharedWithMeSearchQuery = "";
}
toggleBrandSharedWithMeTable(): void {
  // Toggle the visibility of the shared-with-me table
  this.brandshowSharedWithMeTable = !this.brandshowSharedWithMeTable;

  // If shared-with-me table is shown, hide the user-defined table
  if (this.brandshowSharedWithMeTable) {
      this.brandshowUserDefinedTable = false;
      this.datashowSharedWithMeTable=false;
      this.datashowUserDefinedTable=false;
  }

  // Reset the search queries for user-defined and shared-with-me tables
  this.datauserDefinedSearchQuery = "";
  this.datasharedWithMeSearchQuery = "";
  this.branduserDefinedSearchQuery = "";
  this.brandsharedWithMeSearchQuery = "";
}

  /**
 * Initializes the component for creating a custom group.
 * Resets various component properties and fetches the saved selections list.
 */
OnCreateCustomGroup(): void {
  // Reset properties related to custom group creation/editing
  this.customReportName = "";
  this.isSaveDisable = false;
  this.isCreateEdit = true;
  this.isUpdateCustomGroup = false;
  
  // Clear saved selections lists
  this.dataExplorerSavedSelectionList = [];
  this.dataExplorerSharedWithMeSelectionList = [];
  this.brandAnalysisSavedSelectionList = [];
  this.brandAnalysisSharedWithMeSelectionList = [];

  // Set header text for custom report creation
  this.headerText = this.createCustomReportHeader;

  // Fetch saved selections list with null report item
  this.GetSavedSelectionsList(null);
}

  /**
 * Retrieves the saved selections list from the server based on the report item.
 * Updates the component's savedSelectionList and sharedWithMeSavedSelectionList.
 * If reportItem is provided, marks the corresponding selections as checked.
 * 
 * @param reportItem The custom report item to determine the saved selections to check.
 */
GetSavedSelectionsList(reportItem: CustomReports | null): void {
  // Show loader while fetching data
  this._loaderService.showLoader();

  // Retrieve input data for the API call
  let inputData = this.GetInputObject();

  // Call the custom report service to get saved selections
  this._customReportService.getSavedSelection(inputData).subscribe((result: results) => {
      // Check if the result has valid data
      if (result.HasValidData) {
          // Parse the JSON data to AllSavedSelectionList object
          let allSelectionList: AllSavedSelectionList = JSON.parse(result.Data);

          // Update the component's savedSelectionList and sharedWithMeSavedSelectionList
          this.dataExplorerSavedSelectionList = allSelectionList.dataExplorerUserDefinedSavedSelection;
          this.dataExplorerSharedWithMeSelectionList = allSelectionList.dataExplorerSharedWithMeSavedSelection;
          this.brandAnalysisSavedSelectionList = allSelectionList.brandAnalysisUserDefinedSavedSelection;
          this.brandAnalysisSharedWithMeSelectionList = allSelectionList.brandAnalysisSharedWithMeSavedSelection;
          // If reportItem is provided and has saved selections, mark them as checked
          if (reportItem != null && reportItem.saved_selection.length > 0) {
              let arrayCustomID: string[] = reportItem.saved_selection.map((x: SelectionDetails) => x.saved_selection_id);
              this.findSelectionMakeChecked(arrayCustomID, this.dataExplorerSavedSelectionList);
              this.findSelectionMakeChecked(arrayCustomID, this.dataExplorerSharedWithMeSelectionList);
              this.findSelectionMakeChecked(arrayCustomID, this.brandAnalysisSavedSelectionList);
              this.findSelectionMakeChecked(arrayCustomID, this.brandAnalysisSharedWithMeSelectionList);
          }
      }

      // Hide loader after fetching data
      this._loaderService.hideLoader();
  });
}

  /**
 * Finds and marks selections as checked based on the given array of custom IDs.
 * Updates the isChecked property for the matching selections and sets the save disable flag.
 * 
 * @param arrayCustomID An array of custom report IDs to search for in the saved list.
 * @param savedList The list of saved selections to search within.
 */
findSelectionMakeChecked(arrayCustomID: string[], savedList: CustomReportsSavedSelection[]): void {
  // Filter the savedList to find selections that match the arrayCustomID
  let userreportData = savedList.filter((x: CustomReportsSavedSelection) => {
      return arrayCustomID.indexOf(x.saved_selection_id) > -1;
  });

  // If matching selections are found, mark them as checked and set the save disable flag
  if (userreportData != null && userreportData.length > 0) {
      userreportData.forEach((x: CustomReportsSavedSelection) => {
          x.isChecked = true;
      });
      this.isSaveDisable = true;
  }
}

/**
 * Handles the click event to display custom report information.
 * Sets the flag to show the custom report information and populates
 * the customReportInfoList with saved selection names from the provided custom report item.
 * @param customReportItem The custom report item containing the information to display.
 */
OnCustomReportInfoClick(customReportItem: CustomReports): void {
  // Set the flag to show the custom report information
  this.showCustomReportInfo = true;
  // Check if the custom report item has saved selections
  if (customReportItem.saved_selection != null && customReportItem.saved_selection.length > 0) {
      // Map saved selection names and assign to customReportInfoList
      this.customReportInfoList = customReportItem.saved_selection.map((x: SelectionDetails) => x.saved_selection_name);
  }
}


  /**
 * Handles the save action for custom groups.
 * Checks for selected items, displays an alert if none are selected.
 * Displays save confirmation or update save confirmation based on the context.
 */
OnSaveCustomGroup(): void {
  // Check if any selection is made
  if (!this.checkForSelection()) {
      // Display alert if no selection is made
      this.AlertPopup(this.errorTextSavedSelection);
  } else {
      // If it's an update operation, call onUpdateSaveConfirm
      if (this.isUpdateCustomGroup) {
          this.onUpdateSaveConfirm();
      } else {
          // Otherwise, display the save popup
          this.savePopup = true;
      }
  }
}

 /**
 * Prepares the component to show an update confirmation dialog.
 * Sets the confirmation message, shows the dialog, and updates the flag for custom group update.
 */
onUpdateSaveConfirm(): void {
  // Set the confirmation message to the update confirmation text
  this.confirmationMessage = this.updateConfirmationText;
  
  // Show the confirmation dialog
  this.isShowDialog = true;

  // Set the flag to indicate it's an update operation
  this.isUpdateCustomGroup = true;
}

  /**
 * Checks if any selection is made from either the user-defined or shared saved selections.
 * 
 * @returns true if any selection is made, otherwise false.
 */
checkForSelection(): boolean {
  // Check if any user-defined selection is checked
  let dEuserDefinedSelection = this.dataExplorerSavedSelectionList.some((x: CustomReportsSavedSelection) => {
      return x.isChecked;
  });

  // Check if any shared selection is checked
  let dEsharedSelection = this.dataExplorerSharedWithMeSelectionList.some((x: CustomReportsSavedSelection) => {
      return x.isChecked;
  });
  let bAuserDefinedSelection = this.brandAnalysisSavedSelectionList.some((x: CustomReportsSavedSelection) => {
    return x.isChecked;
  });
  let bAsharedSelection = this.brandAnalysisSharedWithMeSelectionList.some((x: CustomReportsSavedSelection) => {
      return x.isChecked;
  });
  // Return true if either user-defined or shared selection is checked, otherwise false
  return dEuserDefinedSelection || dEsharedSelection||bAuserDefinedSelection||bAsharedSelection;
}

  /**
 * Handles the submit action for custom groups.
 * Validates the custom report name, checks for selected items,
 * and saves the custom report if valid selections are made.
 */
OnSubmitCustomGroup(): void {
  // Check if custom report name is empty or only whitespace
  if (this.customReportName == null || this.customReportName.trim().length === 0) {
      // Display alert if custom report name is invalid
      this.AlertPopup(this.errorTextCustomReportName);
  } else {
      // Show loader
      this._loaderService.showLoader();

      // Filter selected saved items
      let dataExplorerSelectedSavedItems = this.dataExplorerSavedSelectionList.filter((x: CustomReportsSavedSelection) => {
          return x.isChecked;
      });

      // Filter selected shared items
      let dataExplorerSelectedSharedItems = this.dataExplorerSharedWithMeSelectionList.filter((x: CustomReportsSavedSelection) => {
          return x.isChecked;
      });
      let brandAnalysisSelectedSavedItems = this.brandAnalysisSavedSelectionList.filter((x: CustomReportsSavedSelection) => {
        return x.isChecked;
    });

    // Filter selected shared items
    let brandAnalysisSelectedSharedItems = this.brandAnalysisSharedWithMeSelectionList.filter((x: CustomReportsSavedSelection) => {
        return x.isChecked;
    });

      // Check if any selected items exist
      if (dataExplorerSelectedSavedItems.length > 0 || dataExplorerSelectedSharedItems.length > 0
        ||brandAnalysisSelectedSavedItems.length > 0 || brandAnalysisSelectedSharedItems.length > 0) {
          // Prepare custom reports object
          let CustomReportsObj: CustomReports = {
              client_name: this.client_name,
              study_name: this.study_name,
              module_id: this.module_id,
              saved_selection: this.FiterSelectedSavedSelection(dataExplorerSelectedSavedItems, dataExplorerSelectedSharedItems,brandAnalysisSelectedSavedItems,brandAnalysisSelectedSharedItems),
              custom_report_id: "",
              custom_report_name: this.customReportName,
              is_active: true,
              is_downloading: false,
              sharedwith: [],
              status: "",
              user_name: "",
              is_shared: false,
          };

          // Call the service to save custom report
          this._customReportService.SaveCustomReport(CustomReportsObj).subscribe((result: results) => {
              if (result.HasValidData) {
                  let response = JSON.parse(result.Data);
                  if (response === "1") {
                      // Close save popup and display success message
                      this.savePopup = false;
                      this.AlertPopup(this.messageTextInsertCustomReport, "Alert");
                      this.OnCancelCustomGroup();
                      this.GetAllCustomReports();
                      this.showUserDefinedTable = true;
                      this.showSharedWithMeTable = false;
                  } else if (response === "0") {
                      // Display alert if custom report name already exists
                      this.AlertPopup(this.errorTextCustomReportNameExist);
                  } else {
                      // Display generic error message
                      this.AlertPopup("Something wrong");
                  }
              }
              // Hide loader
              this._loaderService.hideLoader();
          });
      } else {
          // Display alert if no selection is made
          this.AlertPopup(this.errorTextSavedSelection);
          // Hide loader
          this._loaderService.hideLoader();
      }
  }
}

 /**
 * Handles the update action for custom reports.
 * Validates the selected items and updates the custom report if valid selections are made.
 */
UpdateCustomReport(): void {
  // Show loader
  this._loaderService.showLoader();

  // Filter selected saved items
  let dataselectedSavedItems = this.dataExplorerSavedSelectionList.filter((x: CustomReportsSavedSelection) => {
      return x.isChecked;
  });

  // Filter selected shared items
  let dataselectedSharedItems = this.dataExplorerSharedWithMeSelectionList.filter((x: CustomReportsSavedSelection) => {
      return x.isChecked;
  });
  // Filter selected saved items
  let brandselectedSavedItems = this.brandAnalysisSavedSelectionList.filter((x: CustomReportsSavedSelection) => {
    return x.isChecked;
});

// Filter selected shared items
let brandselectedSharedItems = this.brandAnalysisSharedWithMeSelectionList.filter((x: CustomReportsSavedSelection) => {
    return x.isChecked;
});

  // Check if any selected items exist
  if (dataselectedSavedItems.length > 0 || dataselectedSharedItems.length > 0 ||brandselectedSavedItems.length > 0 || brandselectedSharedItems.length > 0) {
      // Prepare custom reports object
      let CustomReportsObj: CustomReports = this.selectedCustomReportItem;
      CustomReportsObj.saved_selection = this.FiterSelectedSavedSelection(dataselectedSavedItems, dataselectedSharedItems,brandselectedSavedItems,brandselectedSharedItems);

      // Call the service to update custom report
      this._customReportService.UpdateCustomReport(CustomReportsObj).subscribe((result: results) => {
          if (result.HasValidData) {
              let response = JSON.parse(result.Data);
              if (response === "1") {
                  // Display success message and update UI
                  this.AlertPopup(this.messageTextUpdateCustomReport, "Alert");
                  this.OnCancelCustomGroup();
                  this.GetAllCustomReports();
                  this.showUserDefinedTable = true;
                  this.showSharedWithMeTable = false;
              } else {
                  // Display generic error message
                  this.AlertPopup("Something wrong");
              }
          }
          // Hide loader
          this._loaderService.hideLoader();
      });
  } else {
      // Display alert if no selection is made
      this.AlertPopup(this.errorTextSavedSelection);
      // Hide loader
      this._loaderService.hideLoader();
  }
}

  /**
 * Filters and combines selected saved selections from both user-defined and shared selections.
 * 
 * @param selectedSavedItems An array of selected saved items from user-defined selections.
 * @param selectedSharedItems An array of selected saved items from shared selections.
 * @returns An array of combined selected saved selections.
 */
FiterSelectedSavedSelection(dataselectedSavedItems: CustomReportsSavedSelection[], dataselectedSharedItems: CustomReportsSavedSelection[],brandselectedSavedItems: CustomReportsSavedSelection[],brandselectedSharedItems: CustomReportsSavedSelection[]): SelectionDetails[] {
  // Initialize an empty array to hold the combined selected saved selections
  let savedSelection: SelectionDetails[] = [];

  // Get selected saved selections from user-defined items
  let dataSelectedItemsSavedSelection = this.GetSelectedSavedSelection(dataselectedSavedItems);

  // Get selected saved selections from shared items
  let dataSelectedItemsSharedSelection = this.GetSelectedSavedSelection(dataselectedSharedItems);
  // Get selected saved selections from user-defined items
  let brandSelectedItemsSavedSelection = this.GetSelectedSavedSelection(brandselectedSavedItems);

  // Get selected saved selections from shared items
  let brandSelectedItemsSharedSelection = this.GetSelectedSavedSelection(brandselectedSharedItems);
  // Combine the selected saved selections
  savedSelection = [...dataSelectedItemsSavedSelection, ...dataSelectedItemsSharedSelection,...brandSelectedItemsSavedSelection,...brandSelectedItemsSharedSelection];

  // Return the combined saved selections
  return savedSelection;
}

 /**
 * Converts an array of selected saved items into an array of SelectionDetails.
 * 
 * @param selectedItems An array of selected saved items to convert.
 * @returns An array of SelectionDetails containing the selected saved items.
 */
GetSelectedSavedSelection(selectedItems: CustomReportsSavedSelection[]): SelectionDetails[] {
  // Initialize an empty array to hold the converted selected saved items
  let savedSelection: SelectionDetails[] = [];

  // Loop through each selected item and convert it to a SelectionDetails object
  selectedItems.forEach((element: CustomReportsSavedSelection) => {
      savedSelection.push({
          saved_selection_id: element.saved_selection_id,
          saved_selection_name: element.selection_name,
          failed_comments: "",  // Initialize with an empty string
          status: "",           // Initialize with an empty string
      });
  });

  // Return the converted selected saved items
  return savedSelection;
}

  
  /**
 * Resets the custom group form and switches back to the default view.
 * 
 * This method is called when the user cancels the custom group creation or update process.
 */
OnCancelCustomGroup(): void {
  // Reset flags to default values
  this.isCreateEdit = false;
  this.isUpdateCustomGroup = false;

  // Set the header text to default
  this.headerText = this.listCustomReportHeader;
}

  /**
 * Handles the click event on a checkbox for a selection item.
 * 
 * Toggles the isChecked property of the selection item and checks if any items are selected.
 * If items are selected, it sets the isSaveDisable flag to true; otherwise, it sets it to false.
 * 
 * @param selectionItem The selection item whose checkbox was clicked.
 */
onCheckBoxClick(selectionItem: CustomReportsSavedSelection): void {
  // Toggle the isChecked property of the selection item
  selectionItem.isChecked = !selectionItem.isChecked;

  // Check if savedSelectionList is not null and has items
  //if ((this.dataExplorerSavedSelectionList != null && this.dataExplorerSavedSelectionList.length > 0)||this.brandAnalysisSavedSelectionList != null && this.brandAnalysisSavedSelectionList.length > 0) {
      
      // Check if any items in savedSelectionList are checked
      let checkedUserDefined = this.dataExplorerSavedSelectionList.some((x: CustomReportsSavedSelection) => {
          return x.isChecked;
      });

      // Check if any items in sharedWithMeSavedSelectionList are checked
      let checkedSharedWithMe = this.dataExplorerSharedWithMeSelectionList.some((x: CustomReportsSavedSelection) => {
          return x.isChecked;
      });
      // Check if any items in savedSelectionList are checked
      let brandcheckedUserDefined = this.brandAnalysisSavedSelectionList.some((x: CustomReportsSavedSelection) => {
        return x.isChecked;
    });

    // Check if any items in sharedWithMeSavedSelectionList are checked
    let brandcheckedSharedWithMe = this.brandAnalysisSharedWithMeSelectionList.some((x: CustomReportsSavedSelection) => {
        return x.isChecked;
    });

      // Set isSaveDisable flag based on whether any items are checked
      if (checkedUserDefined || checkedSharedWithMe||brandcheckedUserDefined||brandcheckedSharedWithMe) {
          this.isSaveDisable = true;
      } else {
          this.isSaveDisable = false;
      }
 // }
}

  /**
 * Handles the click event when deleting a custom report.
 * 
 * Sets the selectedCustomReportItem and customReportType properties based on the provided parameters.
 * Displays a confirmation message for the deletion and opens the dialog.
 * 
 * @param customReportItem The custom report item to be deleted.
 * @param reportType The type of the custom report (e.g., 'userdefined' or 'sharedwithme').
 */
OnDeleteClick(customReportItem: CustomReports, reportType: string): void {
  // Set the selected custom report item and report type
  this.selectedCustomReportItem = customReportItem;
  this.customReportType = reportType;

  // Set the confirmation message for deletion
  this.confirmationMessage = this.deleteConfirmationText;

  // Open the dialog for confirmation
  this.isShowDialog = true;

  // Ensure that the operation is not an update
  this.isUpdateCustomGroup = false;
}

 
onConfirmation(operation: number): void {
  if (operation===1) {
      // If updating a custom report
      if (this.isUpdateCustomGroup) {
          this.UpdateCustomReport();
      } 
      // If deleting a custom report
      else {
          this.deleteCustomReports();
      }

      // Close the dialog
      this.isShowDialog = false;
  } 
  else {
      // User canceled the action
      // Reset the dialog state
      this.isShowDialog = false;
      this.customReportType = "";
  }
}


  /**
 * Deletes a custom report based on the selected custom report item and report type.
 * 
 * Sends a request to delete the custom report and handles the response accordingly.
 */
deleteCustomReports(): void {
  // Show loader while deleting
  this._loaderService.showLoader();

  // Create delete object with custom report ID and report type
  let deleteObj: DeleteInputs = {
      custom_report_id: this.selectedCustomReportItem.custom_report_id,
      custom_report_type: this.customReportType
  };

  // Call the delete custom report service method
  this._customReportService.DeleteCustomReport(deleteObj).subscribe((result: results) => {
      if (result.HasValidData) {
          let response = JSON.parse(result.Data);

          if (response === "1") {
              // Remove the deleted custom report from the appropriate list based on its type
              if (deleteObj.custom_report_type === "userdefined") {
                  this.removeCustomGroupOnID(this.userDefinedReportsList, deleteObj.custom_report_id);
              } else {
                  this.removeCustomGroupOnID(this.sharedWithMeReportsList, deleteObj.custom_report_id);
              }

              // Display delete confirmation message
              this.AlertPopup(this.messageTextDeleteCustomReport, "Alert");
          } else {
              // Display generic error message if delete operation fails
              this.AlertPopup("Something wrong");
          }
      }
      
      // Hide loader after operation completion
      this._loaderService.hideLoader();
  });
}

  /**
 * Removes a custom report from the given custom report list based on its ID.
 * 
 * @param customReportList The list of custom reports from which to remove the report.
 * @param customReportId The ID of the custom report to be removed.
 */
removeCustomGroupOnID(customReportList: CustomReports[], customReportId: string): void {
  // Find the index of the custom report with the given ID in the list
  let findCustomReport = customReportList.findIndex((x: CustomReports) => {
      return x.custom_report_id === customReportId;
  });

  // Check if the custom report with the given ID is found
  if (findCustomReport > -1) {
      // Remove the custom report from the list
      customReportList.splice(findCustomReport, 1);
  }
}

  /**
 * Closes the save popup and clears the custom report name input.
 */
onSavePopupClose(): void {
  // Hide the save popup
  this.savePopup = false;
  
  // Clear the custom report name input
  this.customReportName = "";
}

  /**
 * Displays the summary information of a selected saved item.
 * 
 * @param savedItem - The selected saved item to display summary information for.
 */
OnSummaryInfoClick(savedItem: CustomReportsSavedSelection): void {
  // Set flag to display the saved selection summary information
  this.showSavedSelectionInfo = true;
  
  // Assign the summary from the selected saved item to the component property
  this.selectionSummary = savedItem.selection_summary;
}

  /**
 * Closes the custom report information popup.
 */
onCustomInfoPopupClose(): void {
  // Hide the custom report information popup
  this.showCustomReportInfo = false;
}

  /**
 * Closes the saved selection information popup.
 */
onSavedSelectionPopupClose(): void {
  // Hide the saved selection information popup
  this.showSavedSelectionInfo = false;
}

 /**
 * Handles the update action for a custom report.
 * Populates the form with the selected report's data and retrieves saved selections.
 * 
 * @param reportItem - The selected custom report to be updated.
 */
OnUpdateReportClick(reportItem: CustomReports): void {
  // Set flags for edit mode and update action
  this.isCreateEdit = true;
  this.isUpdateCustomGroup = true;
  this.isSaveDisable = false;

  // Set the selected report item for update
  this.selectedCustomReportItem = reportItem;

  // Set the header text for the custom report form
  this.headerText = this.editCustomReportHeader;

  // Clear saved selection lists
  this.dataExplorerSavedSelectionList = [];
  this.dataExplorerSharedWithMeSelectionList = [];
  // Clear saved selection lists
  this.brandAnalysisSavedSelectionList = [];
  this.brandAnalysisSharedWithMeSelectionList = [];

  // Retrieve saved selections for the selected report
  this.GetSavedSelectionsList(reportItem);
}

  /**
 * Handles the duplication action for a custom report.
 * Populates the form with the selected report's data for duplication and retrieves saved selections.
 * 
 * @param reportItem - The selected custom report to be duplicated.
 */
OnDuplicateReportClick(reportItem: CustomReports): void {
  // Set flags for create/edit mode and duplication action
  this.isCreateEdit = true;
  this.isUpdateCustomGroup = false;
  this.isSaveDisable = false;

  // Set the selected report item for duplication
  this.selectedCustomReportItem = reportItem;

  // Set the header text for the custom report form to indicate duplication
  this.headerText = this.duplicateCustomReportHeader;

   // Clear saved selection lists
   this.dataExplorerSavedSelectionList = [];
   this.dataExplorerSharedWithMeSelectionList = [];
   // Clear saved selection lists
   this.brandAnalysisSavedSelectionList = [];
   this.brandAnalysisSharedWithMeSelectionList = [];
  this.customReportName = "";

  // Retrieve saved selections for the selected report
  this.GetSavedSelectionsList(reportItem);
}

  /**
 * Handles the share action for a custom report.
 * Initializes email lists and retrieves user list for sharing.
 * 
 * @param reportItem - The selected custom report to be shared.
 */
OnShareReportClick(reportItem: CustomReports): void {
  // Initialize email lists and selected email list
  this.userEmailList = [];
  this.selectedEmailList = [];
  this.filteredEmailList = [];

  // Set the selected report item for sharing
  this.selectedCustomReportItem = reportItem;

  // Display the share selection popup
  this.showShareSelectionPopup = true;

  // Show loader while fetching user list
  this._loaderService.showLoader();

  // Fetch user list for sharing
  this._customReportService.GetUserList(reportItem).subscribe((result: results) => {
      if (result.HasValidData) {
          let response = JSON.parse(result.Data);

          // Map user emails to email list with isChecked based on shared status
          this.userEmailList = response.map((email: string) => ({
              email: email,
              isChecked: reportItem.sharedwith.indexOf(email) > -1
          }));

          // Map shared emails to selected email list with all checked
          this.selectedEmailList = reportItem.sharedwith.map((email: string) => ({
              email: email,
              isChecked: true
          }));
      }
      // Hide loader after fetching user list
      this._loaderService.hideLoader();
  });
}

  /**
 * Closes the share selection popup.
 */
OnSharePopupClose(): void {
  this.showShareSelectionPopup = false;
}

  /**
 * Handles saving the shared report and updating its sharing status.
 */
OnShareReportSaveClick(): void {
  let reportItem = this.selectedCustomReportItem;

  // Check if reportItem is valid
  if (reportItem != null && reportItem != undefined) {
      
      // Show loader while processing
      this._loaderService.showLoader();

      // Update the shared status and list of emails
      reportItem.is_shared = true;
      reportItem.sharedwith = this.selectedEmailList.map((x: EmailList) => x.email);

      // Call the service to share the report
      this._customReportService.ShareCustomReport(reportItem).subscribe((result: results) => {
          
          // Check if response has valid data
          if (result.HasValidData) {
              let response = JSON.parse(result.Data);

              // Check response for success
              if (response == "1") {
                  this.AlertPopup(this.messageTextSharedCustomReport, "Alert");
                  this.OnSharePopupClose();
                  this.GetAllCustomReports();
              } else {
                  this.AlertPopup("Something wrong");
              }
          }
          
          // Hide loader after processing
          this._loaderService.hideLoader();
      });
  }
}

  /**
 * Handles the click event of the email checkbox.
 * Toggles the checkbox state and updates the selected email list.
 * 
 * @param emailItem - The email item that was clicked.
 */
OnEmailCheckBoxClick(emailItem: EmailList): void {
    
  // Toggle the checkbox state
  emailItem.isChecked = !emailItem.isChecked;

  // Update the selected email list based on checkbox status
  this.selectedEmailList = this.userEmailList.filter((email: EmailList) => {
      return email.isChecked;
  });
}

  /**
 * Handles the input event of the email search input field.
 * Filters the email list based on the input value and updates the filtered email list.
 * 
 * @param event - The input event triggered by the email search input field.
 */
OnEmailInput(event: any): void {
    
  // Get the input value and trim it
  const inputValue = event.target?.value?.toLowerCase().trim();
  
  // Check if there's an input value
  if (inputValue) {
      
      // Filter the userEmailList to find emails that contain the input value
      this.filteredEmailList = this.userEmailList.filter((email: EmailList) =>
          email.email.toLowerCase().includes(inputValue)
      );
  } else {
      
      // If no input value, clear the filteredEmailList
      this.filteredEmailList = [];
  }
}

  /**
 * Handles the click event to initiate report download.
 * Sets the selectedCustomReportItem for downloading and shows the download dialog.
 * 
 * @param report - The custom report item to be downloaded.
 */
OnDownloadReportClick(report: CustomReports): void {
    
  // Show the download dialog
  if(this.hasTimePeriodMapping)
    this.isShowDialogDownload = true;
  else
    this.isShowDialogForNoTimePeriod=true
  
  // Set the selected report item for downloading
  this.selectedCustomReportItem = report;
}


onConfirmationDownload(operation: number): void {
    
  if (operation==1) {
      this.ProcessDownloadReport(true);
      // Close the download dialog
  this.isShowDialogDownload = false;
  } else if (operation==0){
   // this.isShowDialogDownload = false;
    this.ProcessDownloadReport(false);
    // Close the download dialog
  this.isShowDialogDownload = false;
      // // User canceled the download action
  }
  else {
      // Reset the dialog state
      this.isShowDialogDownload = false;
  }
}
onConfirmationDownloadNoTimePeriod(operation: number): void{
  if (operation==1) {
    this.ProcessDownloadReport(false);
    // Close the download dialog
  this.isShowDialogForNoTimePeriod = false;
} 
else {
    // Reset the dialog state
    this.isShowDialogForNoTimePeriod = false;
}
}
ProcessDownloadReport(withLatestTimePeriod:boolean){
  // Show loader while downloading
  this._loaderService.showLoader();
  let reportData:CustomReportForDownload={
    ReportItem:this.selectedCustomReportItem,
    WithLatestTimePeriod:withLatestTimePeriod
  }
  // Call the service to download the custom report
  this._customReportService.DownloadCustomReport(reportData).subscribe((result: results) => {
      
      if (result.HasValidData) {
          
          // Parse the response data
          let response = JSON.parse(result.Data);
          
          if (response == "1") {
              // Show success alert
              this.AlertPopup(this.messageTextDownloadCustomReport, "Alert");
              
              // Refresh the custom reports list
              this.GetAllCustomReports();
          } else {
              
              // Show error alert
              this.AlertPopup("Something wrong");
          }
      }
      
      // Hide the loader
      this._loaderService.hideLoader();
  });
  
  
}

  OnExportDownloadsClick(){
    this.showExportsDownloadsPopup=true;
  }
  onCloseExportDownloads() {
    this.showExportsDownloadsPopup = false;
  }
  OnRefreshCustomReports(){
    this.GetAllCustomReports();
  }
  closeCustomReportPopup(): void {
    // Emit an event to notify the parent component to close the popup
    this.closePopup.emit();
  }
  /**
   * to show the error with message
   * @param message - . string text
   */
  AlertPopup(message:string,headerTxt:string="Error"):void{
    const alertObj:AlertMessageModel={header:headerTxt,message:message,show:true};
    this._alertService.showAlert(alertObj);
  }
  onKeyDown(event: KeyboardEvent): void {
    // Get the pressed key
    const pressedKey = event.key;

    // Allow alphanumeric characters, space, and backspace; prevent other keys

    //onst isValidKey = /^[A-Za-z0-9\s\-_]+$/.test(pressedKey);

    //const isValidKey = /^[^\s@]+$/.test(pressedKey);
    const isValidKey = /^[^\@]+$/.test(pressedKey) || pressedKey === ' ';

    if (!isValidKey) {
      event.preventDefault();
    }
  }
}
