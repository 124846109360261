import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import {
  DataArray,
  DataObject,
  ExcelDataModel,
  ChartDetails,
  documentRepo
} from '../models/crosstab.model';

@Injectable({
  providedIn: 'root',
})
export class CrosstabService {
  apiBaseUrl = environment.baseApiUrl;
  customColors = [
    '#802AB7',
    '#00B6FF',
    '#2C70A3',
    '#802A71',
    '#EFB467',
    '#47B7AE',
    '#F9C099',
    '#2A80B7',
    '#B78000',
    '#2A80FF',
    '#00C7E8',
    '#1B4C96',
    '#571F8A',
    '#077A99',
    '#00C7E8',
  ];
  private crossTabDataSubject = new BehaviorSubject<any>('');
  crossTabData$ = this.crossTabDataSubject.asObservable();

  private variableTabDataSubject = new BehaviorSubject<any>('');
  variableTabData$ = this.variableTabDataSubject.asObservable();

  private crossTabOuputDataSubject = new BehaviorSubject<any>('');
  crossTabOuputDataSubject$ = this.crossTabOuputDataSubject.asObservable();

  private VariableTabNullDataSubject = new BehaviorSubject<any>('');
  VariableTabNullDataSubject$ = this.VariableTabNullDataSubject.asObservable();

  private ChartDataSubject = new BehaviorSubject<any>('');
  ChartDataSubject$ = this.ChartDataSubject.asObservable();

  private GrpChartDataSubject = new BehaviorSubject<any>('');
  GrpChartDataSubject$ = this.GrpChartDataSubject.asObservable();

  private LineChartDataSubject = new BehaviorSubject<any>('');
  LineChartDataSubject$ = this.LineChartDataSubject.asObservable();

  private StackChartDataSubject = new BehaviorSubject<any>('');
  StackChartDataSubject$ = this.StackChartDataSubject.asObservable();
  selectedStudyName = new Subject<string>();
  constructor(private http: HttpClient) {}

  getClientStudyData(
    data: {
      client_code: string;
      study: string;
    },
    isBrandAnalysis: boolean
  ): Observable<any> {
    let inputData = {
      client_code: data.client_code,
      study: data.study,
      isBrandAnalysis: isBrandAnalysis,
    };
    return this.http
      .post<{ client_code: string; study: string; isBrandAnalysis: boolean }>(
        this.apiBaseUrl + 'api/DataExplorer/getLeftPanelData',
        inputData
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(error);
        })
      );
  }

  getVariableData(value: any, isBrandAnalysis: boolean): Observable<any> {
    const path = isBrandAnalysis
      ? 'api/DataExplorer/getLoadDataBrandAnalysis'
      : 'api/DataExplorer/getLoadData';
    return this.http.post<any>(this.apiBaseUrl + path, value).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError(error);
      })
    );
  }

  setCrossTabData(data: any, SelectedStudy: string, ClientCode: string) {
    const newData = { data, SelectedStudy, ClientCode };
    this.crossTabDataSubject.next(newData);
  }

  setVarCrossTabData(
    data: any,
    SelectedStudy: string,
    ClientCode: string,
    entryData: any
  ) {
    const newData = { data, SelectedStudy, ClientCode, entryData };
    this.VariableTabNullDataSubject.next(newData);
  }

  setVariableTabData(data: any) {
    const newData = { data };
    this.variableTabDataSubject.next(newData);
  }

  setCrossTabOuputData(data: any) {
    const newData = { data };
    this.crossTabOuputDataSubject.next(newData);
  }

  ChartData(
    data: any,
    listOfCategory: any,
    Significance: string,
    ConfidenceLevel: string,
    numberType: string,
    chartType: string
  ) {
    const newData = {
      data,
      listOfCategory,
      Significance,
      ConfidenceLevel,
      numberType,
      chartType,
    };
    this.ChartDataSubject.next(newData);
  }

  GrpChartData(
    data: any,
    listOfCategory: any,
    Significance: string,
    ConfidenceLevel: string,
    numberType: string,
    chartType: string
  ) {
    const newData = {
      data,
      listOfCategory,
      Significance,
      ConfidenceLevel,
      numberType,
      chartType,
    };
    this.GrpChartDataSubject.next(newData);
  }

  LineChartData(
    data: any,
    listOfCategory: any,
    Significance: string,
    ConfidenceLevel: string,
    numberType: string,
    chartType: string
  ) {
    const newData = {
      data,
      listOfCategory,
      Significance,
      ConfidenceLevel,
      numberType,
      chartType,
    };
    this.LineChartDataSubject.next(newData);
  }

  StackChartData(
    data: any,
    listOfCategory: any,
    Significance: string,
    ConfidenceLevel: string,
    numberType: string,
    chartType: string
  ) {
    const newData = {
      data,
      listOfCategory,
      Significance,
      ConfidenceLevel,
      numberType,
      chartType,
    };
    this.StackChartDataSubject.next(newData);
  }

  getOutPutData(
    dataObject: DataObject,
    clientCode: string,
    studyname: string,
    significance: string,
    isFilteringActive: boolean,
    confidanceLevel: string,
    isTotalBase: boolean,
    SelectedWeight: string,
    weightColumnName: string,
    isBoostFactor: boolean,
    SignificanceVsReference: string,
    columnLength: number,
    entryPointData: any,
    isBrandAnalysis: boolean,
    isAggregatedData: boolean,
    isSemiAggregatedData: boolean,
    isRespondentLevel: boolean,
    aggregatedVariables: string,
    istimePeriodWave: boolean
  ): Observable<any> {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/getCrosstabOutput';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      //value: value,
      //clientCode: clientCode,
      //studyname: studyname

      Row: dataObject.Row,
      RowNesting: dataObject.RowNesting,
      Column: dataObject.Column,
      ColNesting: dataObject.ColNesting,
      Filter: dataObject.Filter,
      clientCode: clientCode,
      studyname: studyname,
      significance: significance,
      isFiltering: isFilteringActive,
      confidenceLevel: confidanceLevel,
      isTotalBase: isTotalBase,
      SelectedWeight: SelectedWeight,
      defaultWeighColumnName: weightColumnName,
      isBoostFactor: isBoostFactor,
      SignificanceVsReference: SignificanceVsReference,
      columnLength: columnLength,
      entryPointData: entryPointData,
      isBrandAnalysis: isBrandAnalysis,
      isAggregatedData: isAggregatedData,
      isSemiAggregatedData: isSemiAggregatedData,
      aggregatedVariables: aggregatedVariables,
      istimePeriodWave: istimePeriodWave,
      //isFiltering: true,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  saveSelectionData(
    module_id: number,
    selection_name: string,
    client_name: string,
    study: string,
    leftpanel_input_data: string,
    output_selection_data: string,
    selection_summary: string,
    is_active: boolean,
    is_shared: boolean,
    modified_by: string,
    shared_with: string,
    leftpanelDataArray: string,
    is_CustomGroup: boolean
  ): Observable<any> {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/saveSelection';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      module_id: module_id,
      selection_name: selection_name,
      client_name: client_name,
      study: study,
      leftpanel_input_data: leftpanel_input_data,
      output_selection_data: output_selection_data,
      selection_summary: selection_summary,
      modified_by: modified_by,
      shared_with: shared_with,
      is_active: is_active,
      is_shared: is_shared,
      entry_point_selection_data: leftpanelDataArray,
      is_CustomGroup: is_CustomGroup,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  saveCustomGroupSelectionData(
    module_id: number,
    selection_name: string,
    client_name: string,
    study: string,
    selection_query: string,
    selection_object: string,
    modified_by: string,
    shared_with: string,
    is_active: boolean,
    is_shared: boolean,
    sortid: number,
    isUpdate: boolean, //true to update , false to save
    isDuplicate: boolean
  ): Observable<any> {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/saveCustomGroupSelection';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      module_id: module_id,
      selection_name: selection_name,
      client_name: client_name,
      study: study,
      selection_query: selection_query,
      selection_object: selection_object,
      modified_by: modified_by,
      shared_with: shared_with,
      is_active: is_active,
      is_shared: is_shared,
      sortid: sortid,
      isUpdate: isUpdate,
      isDuplicate: isDuplicate,
      id: '',
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  deleteCustomCalculationSelectionData(
    client_name: string,
    study: string,
    selectedCustomCalculationName: string,
    customCalculationBucket: string,
    sharedWith: string,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl + 'api/CrosstabOutput/deleteSelectedCustomCalculation';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      selection_name: selectedCustomCalculationName,
      customGroupBucket: customCalculationBucket,
      shareWith: sharedWith,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  // **** Custom Group Section ****

  saveCustomCalculationSelectionData(
    module_id: number,
    selection_name: string,
    client_name: string,
    study: string,
    selection_query: string,
    selection_object: string,
    modified_by: string,
    shared_with: string,
    is_active: boolean,
    is_shared: boolean,
    sortid: number,
    isUpdate: boolean, //true to update , false to save
    isDuplicate: boolean
  ): Observable<any> {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/saveCustomCalculationSelection';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      module_id: module_id,
      selection_name: selection_name,
      client_name: client_name,
      study: study,
      selection_query: selection_query,
      selection_object: selection_object,
      modified_by: modified_by,
      shared_with: shared_with,
      is_active: is_active,
      is_shared: is_shared,
      sortid: sortid,
      isUpdate: isUpdate,
      isDuplicate: isDuplicate,
      id: '',
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  deleteCustomGroupSelectionData(
    client_name: string,
    study: string,
    selectedCustomGroupName: string,
    customGroupBucket: string,
    sharedWith: string,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl + 'api/CrosstabOutput/deleteSelectedCustomGroup';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      selection_name: selectedCustomGroupName,
      customGroupBucket: customGroupBucket,
      shareWith: sharedWith,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  retriveUserDefinedCustomCalculationSelectionData(
    client_name: string,
    study: string,
    is_active: boolean,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl + 'api/CrosstabOutput/retriveUserDefinedCustomCalculationData';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      is_active: is_active,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  retriveUserDefinedCustomGroupSelectionData(
    client_name: string,
    study: string,
    is_active: boolean,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl + 'api/CrosstabOutput/retriveUserDefinedCustomGroupData';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      is_active: is_active,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  retriveSharedWithMeCustomCalculationData(
    client_name: string,
    study: string,
    is_active: boolean,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl +
      'api/CrosstabOutput/retriveSharedWithMeCustomCalculationData';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      is_active: is_active,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  retriveSharedWithMeCustomGroupData(
    client_name: string,
    study: string,
    is_active: boolean,
    module_id: number
  ): Observable<any> {
    const url =
      this.apiBaseUrl + 'api/CrosstabOutput/retriveSharedWithMeCustomGroupData';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      is_active: is_active,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  shareSelectedCustom(
    selected_client: string,
    selected_module: string,
    selected_study: string,
    selectedCustomName: string,
    selectedEmailsToShare: string[],
    sort_id: number,
    is_shared: boolean,
    customType: string
  ) {
          var url ;
          if(customType == 'customGroup')
          {
            url=this.apiBaseUrl + 'api/CrosstabOutput/shareSelectedCustomGroups';
          }
          else
          {
          url=this.apiBaseUrl + 'api/CrosstabOutput/shareSelectedCustomCalculation';
          }
 
    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: selected_client,
      study: selected_study,
      selection_name: selectedCustomName,
      module_id: selected_module,
      shareWith: selectedEmailsToShare.toString(),
      sort_id: sort_id,
      is_shared: is_shared,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  // **** Custom Group Section ****

  //get all saved scenairos data for particular user,client and study
  loadSelectionData(
    client_name: string,
    study: string,
    selectionType: string,
    module_id: number
  ): Observable<any> {
    var url;
    if (selectionType == 'userDefined')
      url =
        this.apiBaseUrl + 'api/CrosstabOutput/getAllUserdefinedSavedSelection';
    else
      url =
        this.apiBaseUrl + 'api/CrosstabOutput/getAllSharedWithMeSavedSelection';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  //downloadFile(folderName: string, fileName: string): Observable<Blob> {
  //  const url = `${this.apiBaseUrl}api/Configuration/download?folderName=${encodeURIComponent(folderName)}&fileName=${encodeURIComponent(fileName)}`;
  //  return this.http.get(url, { responseType: 'blob' });
  //}

  downloadFile(data: documentRepo): Observable<Blob> {
    // Adjust the API endpoint and parameters as needed
    const exportUrl = `${this.apiBaseUrl}api/Configuration/download`;
    return this.http.post(exportUrl, data, { responseType: 'blob' });
  }

  getDocumentStorage(client_name: string, study: string): Observable<any> {
    var url;
    url = this.apiBaseUrl + 'api/Configuration/getDocStorage';
    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      client_code: client_name,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  //get selected scenario's data
  getSavedScenarioData(
    client_name: string,
    study: string,
    selection_name: string,
    scenarioType: string, //userdefined or shared with me
    module_id: number
  ) {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/getSelectedScenarioData';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      selection_name: selection_name,
      scenarioType: scenarioType,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }

  shareLoadSelection(
    selected_client: string,
    selected_module: number,
    selected_study: string,
    selectedCustomGroupName: string,
    selectedEmailsToShare: string[],
    // sort_id: number,
    is_shared: boolean
  ) {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/shareSelections';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: selected_client,
      study: selected_study,
      selection_name: selectedCustomGroupName,
      module_id: selected_module,
      shareWith: selectedEmailsToShare.toString(),
      // sort_id: sort_id,
      is_shared: is_shared,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  //get selected scenario's data

  //get selected scenario's data
  deleteSavedScenario(
    client_name: string,
    study: string,
    selection_name: string,
    selectionType: string,
    shared_with: string,
    module_id: number
  ) {
    const url = this.apiBaseUrl + 'api/CrosstabOutput/deleteSavedScenario';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      client_name: client_name,
      study: study,
      selection_name: selection_name,
      scenarioType: selectionType,
      shared_with: shared_with,
      module_id: module_id,
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  exportToExcel(data: ExcelDataModel): Observable<Blob> {
    // Adjust the API endpoint and parameters as needed
    // exportToExcel(data: ExcelDataModel): Observable<Blob> {
    const exportUrl = `${this.apiBaseUrl}api/CrosstabOutput/excelExport`;
    return this.http.post(exportUrl, data, { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
    //}
    //const exportUrl = `${this.apiBaseUrl}api/CrosstabOutput/excelExport`;
    //return this.http.post(exportUrl, data, { responseType: 'blob' });
  }
  exportToPPT(data: ChartDetails): Observable<Blob> {
    // Adjust the API endpoint and parameters as needed
    const exportUrl = `${this.apiBaseUrl}api/CrosstabOutput/pptExport`;
    return this.http.post(exportUrl, data, { responseType: 'blob' });
  }
  getInsightsData(
    insightsData: any,
    selectedstudy: string,
    selectedclient: string
  ): Observable<string> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(insightsData));
    formData.append('study', selectedstudy);
    formData.append('client', selectedclient);
    return this.http.post(
      `${this.apiBaseUrl}api/CrosstabOutput/getChatGptRepsonse`,
      formData,
      { responseType: 'text' }
    );
  }
  getAllowedUsers(): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiBaseUrl}api/CrosstabOutput/GetAllowedUsers`
    );
  }
}
