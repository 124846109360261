import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, map, Subject } from 'rxjs';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private apiUrl = environment.baseApiUrl + 'api/Upload/';
  private GetStatusData: string = 'getUpdatedStatusData';
  private UploadFileinAzureExplore: string = 'Uploadfile';
  private DownloadFile: string = 'DownloadGenerateFile';
  private DeleteClientIndexData = 'DeleteClientIndexFromElasticSearch';
  private DownloadUsageLogExcel = 'DownloadUsageLogExcel';
  private downloadExcelExport = 'DownloadFile';
  public $submitClickEvent = new Subject<boolean>();
  private emailProc: string = 'getEmailIDs';
  private emailUpdateProc: string = 'saveStudyEmailList';
  private allowedClientProd: string = 'getAllowedClientList';

  private submittedEmailList: any = [];

  constructor(private http: HttpClient) {}

  downloadExportFile(fileName: string) {
    const formData: FormData = new FormData();
    formData.append('path', fileName);
    return this.http
      .post(`${this.apiUrl}${this.downloadExcelExport}`, formData, {
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  deleteIndex(client: any, clientStudy: any, queryData: any) {
    let formData = new FormData();
    formData.append('client', client);
    formData.append('clientStudy', clientStudy);
    formData.append('queryData', JSON.stringify(queryData));
    return this.http
      .post<any>(`${this.apiUrl}${this.DeleteClientIndexData}`, formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error('test'));
        })
      );
  }

  ExcelExportForUsageLog(
    client_name: any,
    study_name: any,
    dataSource: any,
    dataProcess: any,
    fileType: any
  ) {
    let formData = new FormData();
    formData.append('clientName', client_name);
    formData.append('studyName', study_name);
    formData.append('dataSource', dataSource);
    formData.append('dataProcess', dataProcess);
    formData.append('fileType', fileType);
    return this.http
      .post(`${this.apiUrl}${this.DownloadUsageLogExcel}`, formData, {
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          return throwError(error);
        })
      );
  }
  datauploadstatusandgriddata(data: {
    client_name: string;
    study: string;
    dataSource: string;
    dataProces: string;
    fileType: string;
  }): Observable<any> {
    return this.http
      .post<{
        client_name: string;
        study: string;
        dataProces: string;
        dataSource: string;
        fileType: string;
      }>(`${this.apiUrl}${this.GetStatusData}`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(error);
        })
      );
  }

  fileUpload(
    data: any,
    listFiles: any,
    folderName: string,
    emailData: any
  ): Observable<any> {
    //front-end:
    let formData: FormData = new FormData();
    formData.append('foldersName', folderName);
    for (let i = 0; i < listFiles.length; i++) {
      formData.append('DocFile', listFiles[i]);
    }
    formData.append('inputselection', JSON.stringify(data));
    formData.append('emailData', JSON.stringify(emailData));

    return this.http
      .post<any>(`${this.apiUrl}${this.UploadFileinAzureExplore}`, formData)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError(() => new Error(error.error));
        })
      );
  }

  downloadFile(data: any, fileName: any, folderName: any): Observable<any> {
    //front-end:
    let formData: FormData = new FormData();
    formData.append('foldersName', folderName);

    formData.append('DocFileName', fileName);

    formData.append('inputselection', JSON.stringify(data));
    return this.http.post(`${this.apiUrl}${this.DownloadFile}`, formData, {
      responseType: 'json',
    });
  }

  //Get all the users for client
  getEmailList(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.emailProc}`, data).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  //Update email list for current client study
  updateEmailList(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}${this.emailUpdateProc}`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Request error:', error);
          // You can handle the error here and throw a custom error if needed
          return throwError((error: any) => new Error(error));
        })
      );
  }

  //Update email list for current client study
  AllowedClientDetails(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.allowedClientProd}`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error: any) => new Error(error));
      })
    );
  }

  storedEmailListData(data: any) {
    this.submittedEmailList = data;
  }

  getStoredEmailListData() {
    return this.submittedEmailList;
  }
}
